var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"linked-devices"},[_c('div',{staticClass:"config-question"},[_c('h2',[_vm._v(_vm._s(_vm.$t("lab_home.linked_devices_page.linked_devices_title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "lab_home.linked_devices_page.link_each_of_your_lab_devices_to_make_sure_they_get_updated" ))+" ")])]),_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',{staticClass:"table_head"},[_c('th',[_c('h2',[_vm._v(" "+_vm._s(_vm.$tc( "lab_home.linked_devices_page.devices_count", _vm.devices.length, { count: _vm.devices.length } ))+" ")])]),_c('th'),_c('th',[_c('div',{staticClass:"devices-action-btns"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickOnAddDevice()}}},[_vm._v(_vm._s(_vm.$t("lab_home.linked_devices_page.add_device")))])],1)])])]),_c('tbody',[_vm._l((_vm.devices),function(device){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{key:device.id,staticClass:"device-row"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t("lab_home.linked_devices_page.device_name", { name: device.number }))+" ")]),(_vm.labActiveVersion === undefined)?_c('td',{staticClass:"device-info"},[_vm._v(" "+_vm._s(_vm.$t("global.loading"))+" ")]):(_vm.labActiveVersion === device.config['currentVersion'])?_c('td',{staticClass:"device-info"},[_c('v-icon',{attrs:{"color":"black","aria-hidden":"true"}},[_vm._v("mdi-cellphone")]),_vm._v(" "+_vm._s(_vm.$t("lab_home.linked_devices_page.up_to_date"))+" "),(hover)?_c('span',[_vm._v(" - "+_vm._s(_vm.$t("lab_home.linked_devices_page.device_version", { version: device.config["currentVersion"] }))+" - "+_vm._s(_vm.$t("lab_home.linked_devices_page.updated", { date: _vm.formatUpdateDate(device.config["lastUpdateDate"]) }))+" ")]):_vm._e()],1):_c('td',{staticClass:"device-info"},[_c('v-icon',{attrs:{"color":"red","aria-hidden":"true"}},[_vm._v("mdi-cellphone-erase")]),_vm._v(" "+_vm._s(_vm.$t("lab_home.linked_devices_page.out_of_date"))+" "),(hover)?_c('span',[_vm._v(" - "+_vm._s(_vm.$t("lab_home.linked_devices_page.device_version", { version: device.config["currentVersion"] }))+" - "+_vm._s(_vm.$t("lab_home.linked_devices_page.updated", { date: _vm.formatUpdateDate(device.config["lastUpdateDate"]) }))+" ")]):_vm._e()],1),_c('td',{staticClass:"device-action"},[(hover)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.clickOnOpenInFirestore(device.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lab_home.linked_devices_page.firestore")))])]):_vm._e(),(hover)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.clickOnDeleteDevice(device)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("lab_home.linked_devices_page.remove_device")))])]):_vm._e()],1)])]}}],null,true)})]})],2)])],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showQRCodeDialog),callback:function ($$v) {_vm.showQRCodeDialog=$$v},expression:"showQRCodeDialog"}},[_c('v-card',[_c('h2',[_vm._v(_vm._s(_vm.labName))]),_c('h3',[_vm._v("Scan QR code with new device")]),_c('img',{attrs:{"src":_vm.qrCodeImage,"alt":_vm.$t('a11y.qrcode_alt')}}),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.hideDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("global.back"))+" ")])],1)],1),_c('v-snackbar',{attrs:{"absolute":"","bottom":"","left":"","timeout":"30000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.displayDeviceRemovedSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.displayDeviceRemovedSnackbar),callback:function ($$v) {_vm.displayDeviceRemovedSnackbar=$$v},expression:"displayDeviceRemovedSnackbar"}},[_vm._v(" "+_vm._s(_vm.$t("snackbar.information.device_removed"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }