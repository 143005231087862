export class KnowledgeBase {
  key: string;
  label: string;

  constructor(key: string, label: string) {
    this.key = key;
    this.label = label;
  }
}

export const allKnowledgeBase = [
  new KnowledgeBase("eucast2023", "EUCAST"),
  new KnowledgeBase("clsi2020", "CLSI")
];
