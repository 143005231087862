import Vue from "vue";
import { firestorePlugin } from "vuefire";
import App from "./views/App/App.component.vue";
import store from "./store";
import router from "./router";
import vuetify from "./style/vuetify";
import VueI18n from "vue-i18n";
import VueGapi from "vue-gapi";

import messages from "./i18n";
import { API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES } from "@/firebase";
Vue.config.productionTip = false;

Vue.use(firestorePlugin);
Vue.use(VueI18n);
Vue.use(VueGapi, {
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  scope: SCOPES,
  refreshToken: true
});
export const i18n = new VueI18n({ locale: "en", messages });

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
