import { sha256 } from "js-sha256";
import "babel-polyfill";
import * as firebase from "firebase/app";
import { LogAction } from "@/domain/model/LogAction";

export class AST {
  // Class dedicated to log analysis, takes an array of logs for a specific AST
  // and turns it into an object that's easier to manipulate
  startTime: Date;
  endTime: Date;
  logs: any;
  added: number;
  removed: number;
  isKotlin: boolean;
  userDeleted: boolean;
  astIdHash: string;
  sessionId: string;
  appVersion: string;
  astId: string = "";
  species: string | undefined;
  genus: string | undefined;
  groups: Array<string> = [];
  userName: string | undefined;
  sampleType: string | undefined;
  cropOffset: any;
  imageFile: string = "";
  initialAntibiotics: any;
  pixelPerMM: number | undefined;
  preESAntibiotics: any;
  ESSequence: Array<any> = [];
  numQuestions: number = 0;
  knowledgeBase: string | undefined;
  finalAntibiotics: any;
  micAntibiotics: any;
  micsRequestedByAntibiogo: Set<string> = new Set<string>();
  finalAntibiogram: any;
  astscript: any;
  lastESCompleteFlowTs: any;
  userEditedIzds: Array<any> = [];
  ipcAlerts: Array<any> = [];

  constructor(logs: any, candidateAstId: any) {
    const isActionBeforeLastESComplete = (i: number) => {
      const timestampMs = logs[i].timestampMs;
      return (
        timestampMs &&
        this.lastESCompleteFlowTs &&
        timestampMs.valueOf() < this.lastESCompleteFlowTs.valueOf()
      );
    };

    const sortLogsChronologically = () => {
      logs.sort((log1: any, log2: any) => {
        const t1 = log1.timestampMs.valueOf();
        const t2 = log2.timestampMs.valueOf();

        if (t1 < t2) {
          return -1;
        }

        if (t2 > t1) {
          return 1;
        }

        return 0;
      });
    };

    try {
      sortLogsChronologically();
      this.lastESCompleteFlowTs = logs
        .filter(
          (log: any) =>
            log.logEntry.action === LogAction.AntibioticsStateESCompleteFlow
        )
        .at(-1)?.timestampMs;
      this.startTime = logs[0].timestampMs.toDate();
      this.endTime = logs[logs.length - 1].timestampMs.toDate();
      this.logs = logs;
      this.added = 0;
      this.removed = 0;
      this.isKotlin = false;
      this.userDeleted = false; // Whether the user has deleted this AST.
      this.astIdHash = logs[0].astIdHash;
      this.sessionId = logs[0].sessionId;
      this.appVersion = logs[0].appTrack + " " + logs[0].buildType;

      if (candidateAstId && sha256(candidateAstId) === this.astIdHash) {
        this.astId = candidateAstId;
      }
      for (let i = 0; i < logs.length; i++) {
        const payload = logs[i].logEntry.payload;
        switch (logs[i].logEntry.action) {
          case LogAction.HelloKotlin:
            this.isKotlin = true;
            break;
          case LogAction.FormSetSpecies:
            this.species = payload.name;
            this.genus = payload.genus;
            this.groups = payload.groups.map((group: any) => group.name);
            break;
          case LogAction.FormSetUserName:
            this.userName = payload;
            break;
          case LogAction.FormSetSampleType:
            this.sampleType = payload;
            break;
          case LogAction.CroppedPictureSet:
            this.cropOffset = payload;
            break;
          case LogAction.NetworkTaskSucceeded:
            this.imageFile = payload.cloudRef;
            break;
          case LogAction.AntibioticsStateSet:
            if (isActionBeforeLastESComplete.call(this, i)) {
              this.initialAntibiotics = payload.antibiotics;
              this.pixelPerMM = payload.pxPerMm;
            }
            break;
          case LogAction.AntibioticsStateUserEditedIZD:
            if (isActionBeforeLastESComplete.call(this, i)) {
              this.userEditedIzds.push(payload);
            }
            break;
          case LogAction.AntibioticsAdd:
            this.added += 1;
            break;
          case LogAction.AntibioticsRemoveSelected:
            this.removed += 1;
            break;
          case LogAction.AntibioticsStateESStartFlow:
            this.preESAntibiotics = payload.antibiotics;
            break;
          case LogAction.AntibioticsStateIPCAlerts:
            this.ipcAlerts = payload.alerts.map((alert: any) => alert.title);
            break;
          case LogAction.ESStartFlow:
            this.ESSequence = [];
            this.numQuestions = 0;
            this.knowledgeBase = payload.knowledgeBase;
            break;
          case LogAction.ESBeforeRunExpertise:
            this.ESSequence.push(payload);
            break;
          case LogAction.ESPromptFromES:
            this.numQuestions += 1;
            this.ESSequence.push(payload);
            break;
          // userAnsweredESQuestion was renamed to answeredESQuestion.
          // Keep both for backward-compatibility.
          case LogAction.ESAnsweredESQuestion:
          case LogAction.ESUserAnsweredESQuestion:
            this.ESSequence.push(payload);
            break;
          case LogAction.AntibioticsStateESCompleteFlow:
            this.finalAntibiotics = payload.antibiotics;
            this.micAntibiotics = payload.micAntibiotics;
            break;
          case LogAction.ESCompleteFlow:
            if (typeof payload.antibiogram === "string") {
              // Kotlin sends it as a JSON-serialised string, RN sends it as an object.
              this.finalAntibiogram = JSON.parse(payload.antibiogram);
            } else {
              this.finalAntibiogram = payload.antibiogram;
            }
            Object.entries(this.finalAntibiogram.antibiotics)
              .filter((ab: any) => {
                const abValue = ab[1];
                return (
                  abValue.siri === "request M.I.C." &&
                  (abValue.diameter || abValue.cmi) &&
                  abValue.sir &&
                  !abValue.mask
                );
              })
              .forEach((ab: any) => this.micsRequestedByAntibiogo.add(ab[0]));
            if (typeof payload.astScript === "string") {
              this.astscript = payload.astScript;
            }
            break;
          case LogAction.SetAstCompleted:
            // an edit in the AST invalidated the previously completed AST
            if (!payload) {
              this.ESSequence = [];
              this.numQuestions = 0;
              this.finalAntibiotics = undefined;
              this.finalAntibiogram = undefined;
            }
            break;
          case LogAction.DeleteAstById:
            // The user has deleted this AST. We should ignore it.
            // https://github.com/foundationmsf/webconsole/issues/3
            this.userDeleted = true;
            break;
        }
      }
    } catch (err) {
      console.error(err);
      throw Error("Wrong log format");
    }
  }

  getImageUrlPromise() {
    return new Promise((resolve, reject) => {
      let storageUrl = "NOT YET UPLOADED";
      firebase
        .storage()
        .ref(this.imageFile)
        .getDownloadURL()
        .then(url => {
          if (url !== undefined) {
            storageUrl = url;
          }
          resolve(storageUrl);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }

  get complete() {
    return (
      this.species &&
      this.sampleType &&
      this.cropOffset &&
      this.pixelPerMM &&
      this.initialAntibiotics &&
      this.preESAntibiotics &&
      this.finalAntibiotics &&
      this.finalAntibiogram &&
      this.ESSequence &&
      !this.userDeleted &&
      this.appVersion
    );
  }
}

export function logsToAsts(logData: any, astIds: any): Array<any> {
  /* Takes the result of a firestore query, segregates the logs per
      AST id and session, build AST objects and return them as an array. */
  if (!astIds) {
    astIds = [];
  }
  let astIdHashMap: { [index: string]: string } = {};
  astIds.forEach((id: any) => (astIdHashMap[sha256(id)] = id));
  let astLogs: { [index: string]: any } = {};
  logData.forEach((log: any) => {
    let key: any = [log.astIdHash, log.sessionId];
    if (key in astLogs) {
      astLogs[key].push(log);
    } else {
      astLogs[key] = [log];
    }
  });
  let asts: Array<any> = [];
  Object.entries(astLogs).forEach(([key, logs]) => {
    // to turn an array into an object key, javascript turns each value
    // into a string and concatenate with a comma separator, split allows
    // us to retrieve a specific item as long as it's not a character used
    let astIdHash: string = key.split(",")[0];
    asts.push(new AST(logs, astIdHashMap[astIdHash]));
  });

  return asts;
}

export function extractTestsFromAST(ast: any) {
  const testMap = new Map<string, string>();
  Object.entries(ast.finalAntibiogram.tests).forEach(entry => {
    const test_name = entry[0].trim().toLowerCase();
    const test_answer = String(entry[1])
      .trim()
      .toLowerCase();
    testMap.set(test_name, test_answer);
  });
  return testMap;
}

export function getMicSiri(ab: any): string {
  if (ab.siri === "MIC = @mic mg/l") {
    //This is an exception only for MIC antibiotics
    return ab.sir;
  }

  return ab.siri;
}

export function getDDSir(finalAb: any): string {
  let sirOrSiri = finalAb.sir;
  // If the Diameter is empty in the final antibiogram, it means the same antibiotic is used for DD and MIC, only the interpretation of the MIC is kept in the AST output
  if (
    finalAb.diameter === null &&
    finalAb.cmi !== null &&
    finalAb.cmi !== undefined
  ) {
    sirOrSiri = "PS"; // Custom value only for sheet
  } else if (finalAb.sir === undefined) {
    sirOrSiri = "PS";
  }

  return sirOrSiri;
}

export function getDDSiri(finalAb: any): string {
  let sirOrSiri = finalAb.siri;
  // If the Diameter is empty in the final antibiogram, it means the same antibiotic is used for DD and MIC, only the interpretation of the MIC is kept in the AST output
  if (
    finalAb.diameter === null &&
    finalAb.cmi !== null &&
    finalAb.cmi !== undefined
  ) {
    sirOrSiri = "request M.I.C."; // Custom value only for sheet
  } else if (finalAb.siri === undefined) {
    sirOrSiri = "PS";
  }

  return sirOrSiri;
}

export function restoreFullName(ast: any, name: string): string {
  // To log into firebase we had to replace all dots in dictionary keys by dashes
  // To match antibiotics name stored in an array with the dictionary keys, we
  // have to reproduce this transformation. (RN app only)
  //
  // The Kotlin app doesn't perform the transformation in the first place, so
  // we don't need to reverse it and simply return the name as-is.
  if (ast.isKotlin) {
    return name;
  }

  return name.replace(/\./g, "-");
}

export function formationCreationDate(creationDate: any): string {
  // let now = new Date(Date.now());
  const date = new Date(creationDate);
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map(e => e.toString())
    .join("/");
}
