import { db, storage, functions } from "@/firebase";
import firebase from "firebase/app";
import { KnowledgeBase } from "@/model/KnowledgeBase";
import { DefaultESAnswer } from "@/model/defaultESAnswer";

const path = require("path");

const initialConfiguration = {
  version: 1,
  lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
  importPictureEnabled: false,
  selectiveReporting: {},
  allowedAntibiotics: [],
  knowledgeBase: "eucast2023"
};
export default {
  async getLabs(): Promise<any[]> {
    return new Promise<any[]>(resolve => {
      const querySnapshot = db.collection("labs").onSnapshot(querySnapshot => {
        const labs: any[] = [];
        querySnapshot.forEach(doc => {
          let lab = doc.data();
          lab.id = doc.id;
          if (lab.active === undefined || lab.active === true) {
            labs.push(lab);
          }
        });
        return resolve(
          labs.sort((lab1, lab2) => lab1.name.localeCompare(lab2.name))
        );
      });
    });
  },
  async createLab(labName: any): Promise<any> {
    try {
      //CREATE Lab
      const newLab = await db
        .collection("labs")
        .add({ name: labName, active: true });
      const labId = newLab.id;

      //PREPARE BATCH WRITE to upload default config
      var batch = db.batch();
      //active config
      var activeConfRef = db
        .collection("labs")
        .doc(labId)
        .collection("config")
        .doc("activeConfig");

      batch.set(activeConfRef, initialConfiguration);

      //draft config
      var draftConfRef = db
        .collection("labs")
        .doc(labId)
        .collection("config")
        .doc("draftConfig");
      batch.set(draftConfRef, {
        ...initialConfiguration,
        version: initialConfiguration.version + 1
      });

      //RUN BATCH WRITE to save default config
      await batch.commit();

      //Retrieve lab info to update UI
      const labReference = await newLab.get();
      const finalLab: any = labReference.data();
      if (finalLab === undefined) {
        return Promise.reject(new Error());
      }
      finalLab.id = labReference.id;
      return finalLab;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async getLab(labId: string): Promise<any> {
    try {
      const labSnapshot = await db
        .collection("labs")
        .doc(labId)
        .get();
      const lab: any = labSnapshot.data();
      lab.id = labId;
      lab.config = await this.getLabConfig(labId);
      return lab;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async updateLabImportPicture(
    labId: string,
    importPicture: boolean
  ): Promise<any> {
    return db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig")
      .update({
        importPictureEnabled: importPicture,
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
      });
  },
  async updateKnowledgeBase(
    labId: string,
    knowledgeBase: KnowledgeBase
  ): Promise<any> {
    return db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig")
      .update({
        knowledgeBase: knowledgeBase.key,
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
      });
  },
  async updateESDefaultAnswers(
    labId: string,
    esDefaultAnswers: DefaultESAnswer[]
  ): Promise<any> {
    return db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig")
      .update({
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        esDefaultAnswers: esDefaultAnswers
      });
  },
  async saveConfig(labId: string): Promise<any> {
    const draftConfigSnapshot = await db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig")
      .get();

    const activeConfigSnapshot = await db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig")
      .get();

    const draftConfig = draftConfigSnapshot.data();
    const activeConfig = activeConfigSnapshot.data();
    if (draftConfig === undefined || draftConfig.version === undefined) {
      return Promise.reject(new Error("Could not retrieve draft config"));
    }
    let newDraftConfig = {
      ...draftConfig, //copy all other values
      version: draftConfig.version + 1, //increment new draft version
      lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
    };

    //BATCH WRITE to update active and draft config all at once
    var batch = db.batch();

    const activeConfigRef = db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("activeConfig");

    const draftConfigRef = db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .doc("draftConfig");

    batch.set(activeConfigRef, draftConfig);
    batch.set(draftConfigRef, newDraftConfig);

    await batch.commit();

    //copy json file of selective reporting config from draft to active folder
    // Case 1 : new reporting rules
    if (
      draftConfig.selectiveReporting !== undefined &&
      Object.keys(draftConfig.selectiveReporting).length > 0
    ) {
      const saveReportingRulesDraftToActive = functions.httpsCallable(
        "saveReportingRulesDraftToActive"
      );
      await saveReportingRulesDraftToActive({ labId: labId });
    } else if (
      (draftConfig.selectiveReporting === undefined ||
        draftConfig.selectiveReporting === null) &&
      activeConfig !== undefined &&
        activeConfig.selectiveRules !== undefined &&
        activeConfig.selectiveRules !== null
    ) {
      // Case 2 : reporting rules removed
      const removeReportingRulesFromActive = functions.httpsCallable(
        "removeReportingRulesFromActive"
      );
      await removeReportingRulesFromActive({ labId: labId });
    }

    return this.getLabConfig(labId);
  },
  async getLabConfig(labId: string): Promise<any[]> {
    let config: any[string] = [];
    const labConfigSnapshot = await db
      .collection("labs")
      .doc(labId)
      .collection("config")
      .get();
    labConfigSnapshot.docs.forEach(conf => {
      config[conf.id] = conf.data();
    });
    return config;
  },
  async uploadSelectiveReportingRules(
    labId: string,
    file: File
  ): Promise<string> {
    // 1. Upload CSV file to Stoage in /uploaded
    const storageRef = storage.ref(`/labs/${labId}/config/uploaded`);
    const fileName: string = `${path.basename(
      file.name,
      path.extname(file.name)
    )}.${Date.now()}${path.extname(file.name)}`;
    const reportingCSVRef = storageRef.child(fileName);
    const metadata = {
      contentType: "text/csv"
    };
    const snapshot = await reportingCSVRef.put(file, metadata);

    // 2. Check format and save reporting to JSON with cloud function
    const saveSelectiveReportingRules = functions.httpsCallable(
      "saveSelectiveReportingRules"
    );
    try {
      const result = await saveSelectiveReportingRules({
        labId: labId,
        csvName: fileName
      });
      return Promise.resolve(snapshot.ref.getDownloadURL());
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async downloadSelectiveReportingFile(
    labId: string,
    currentFileName: string
  ): Promise<string> {
    const selectiveReportingCSVFileReference = storage.ref(
      `/labs/${labId}/config/uploaded/${currentFileName}`
    );
    try {
      const downloadUrl = await selectiveReportingCSVFileReference.getDownloadURL();
      // This can be downloaded directly:
      return Promise.resolve(downloadUrl);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async removeSelectiveReporting(labId: string) {
    // 2. Check format and save reporting to JSON with cloud function
    const removeSelectiveRulesFunction = functions.httpsCallable(
      "removeSelectiveReportingRules"
    );
    try {
      const result = await removeSelectiveRulesFunction({ labId: labId });
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }
};
