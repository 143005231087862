import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import store from "@/store";
import router from "@/router";
import { allKnowledgeBase, KnowledgeBase } from "@/model/KnowledgeBase";

@Component({})
export default class KnowledgeBaseComponent extends Vue {
  // data
  knowledgeBaseInitialValue = new KnowledgeBase("", "");
  knowledgeBaseCurrentSelection = new KnowledgeBase("", "");
  labId = this.$route.params.labId;
  knowledgeBaseChoices = allKnowledgeBase;
  // view lifecycle
  mounted() {
    this.getLab(this.labId);
  }

  // user actions
  // user actions
  private clickOnBack() {
    router.back();
  }

  private async clickOnSave() {
    const newKnowledgeBase = this.knowledgeBaseCurrentSelection;
    if (newKnowledgeBase === undefined) {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
      return;
    }

    try {
      await labsCollection.updateKnowledgeBase(this.labId, newKnowledgeBase);
      this.$router.back();
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_save_lab_configuration_change")
      );
    }
  }

  // network
  private async getLab(labId: string) {
    try {
      const currentLab = await labsCollection.getLab(labId);
      store.dispatch("setCurrentLabName", currentLab.name); //used for breadcrumb
      let knowledgeBaseKey = currentLab.config.draftConfig.knowledgeBase;
      let knowledgeBase = this.knowledgeBaseChoices.find(
        kb => kb.key === knowledgeBaseKey
      );
      if (knowledgeBase !== undefined) {
        this.knowledgeBaseInitialValue = { ...knowledgeBase };
        this.knowledgeBaseCurrentSelection = { ...knowledgeBase };
      } else {
        throw Error("Could not retrieve knowledge base");
      }
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_retrieve_lab_infos")
      );
    }
  }

  // tools
  private configHasNotChanged(): boolean {
    return (
      this.knowledgeBaseCurrentSelection === undefined ||
      this.knowledgeBaseCurrentSelection.key ===
        this.knowledgeBaseInitialValue.key
    );
  }
}
