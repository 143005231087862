import Vue from "vue";
import Component from "vue-class-component";
import {
  allSelectiveReportingChoices,
  SelectiveReporting
} from "@/model/SelectiveReporting";
import labsCollection from "@/domain/labs";
import store from "@/store";
import router from "@/router";
import utils from "@/domain/utils";
import axios from "axios";

@Component({})
export default class SelectiveReportingComponent extends Vue {
  // data
  labId = this.$route.params.labId;
  selectiveReporting: null | any = null;
  rulesFile: File | null = null;
  displayUploadInProgressLoader: boolean = false;
  displayUploadError: boolean = false;

  // lifecycle
  mounted() {
    this.getLab(this.labId);
  }

  // computed properties
  get hasAlreadySelectiveRules() {
    return (
      this.selectiveReporting !== undefined &&
      this.selectiveReporting !== null &&
      this.selectiveReporting.selectiveAntibiogramRulesPath !== undefined &&
      this.selectiveReporting.selectiveAntibiogramRulesPath !== ""
    );
  }

  get fileName() {
    return this.selectiveReporting !== undefined &&
      this.selectiveReporting.originalFileName !== undefined
      ? utils.formatOriginalFilename(this.selectiveReporting.originalFileName)
      : "";
  }

  get lastUploadDate() {
    return this.selectiveReporting !== undefined &&
      this.selectiveReporting.lastUploadDate !== undefined
      ? utils.formatDate(this.selectiveReporting.lastUploadDate.toDate())
      : "";
  }

  // network
  private async getLab(labId: string) {
    const currentLab = await labsCollection.getLab(labId);
    store.dispatch("setCurrentLabName", currentLab.name); //used for breadcrumb
    this.selectiveReporting = currentLab.config.draftConfig.selectiveReporting;
  }

  // user actions
  private clickOnBack() {
    router.back();
  }

  private async clickOnDownload() {
    try {
      const filename = this.selectiveReporting.originalFileName;
      const downloadUrl = await labsCollection.downloadSelectiveReportingFile(
        this.labId,
        filename
      );
      const response = await axios.get(downloadUrl);
      let blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }

  // it's a hack to have a button behave like a input file element
  private clickUpload() {
    const inputElement = this.$refs.uploadCSVInput as HTMLInputElement;
    inputElement.click(); // will call saveNewFile below with the file in parameter.
  }

  private async saveNewFile(event: HTMLInputEvent) {
    this.displayUploadError = false;
    if (
      event.target !== null &&
      event.target.files !== null &&
      event.target.files.length > 0
    ) {
      const uploadedCsv = event.target.files[0];
      try {
        this.displayUploadInProgressLoader = true;
        await labsCollection.uploadSelectiveReportingRules(
          this.labId,
          uploadedCsv
        );
        this.getLab(this.labId);
        this.displayUploadInProgressLoader = false;
      } catch (e) {
        this.displayUploadInProgressLoader = false;
        this.displayUploadError = true;
        console.error(e);
      }
    }
  }

  private async clickOnRemove() {
    try {
      this.displayUploadInProgressLoader = true;
      this.displayUploadError = false;
      await labsCollection.removeSelectiveReporting(this.labId);
      this.getLab(this.labId);
      this.displayUploadInProgressLoader = false;
    } catch (e) {
      this.displayUploadInProgressLoader = false;
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
