const messages = {
  en: {
    login: {
      continue_with_google: "Continue with Google"
    },
    labs: {
      labs_title: "Labs",
      manage_laboratories_customization_settings_and_devices:
        "Manage laboratories customization settings and devices.",
      labs_count: "No lab | 1 lab | {count} labs",
      add_lab: "Add lab",
      manage: "Manage",
      add_new_lab: "Add new lab",
      lab_name_placeholder: "Lab name",
      back: "Back",
      save: "Save",
      drawer_menu: {
        labs: "Labs",
        logs_export: "AST export"
      }
    },
    lab_home: {
      changes_made: "Changes made",
      continue_making_changes_or_send_to_linked_devices:
        "Continue making changes or send them to linked devices",
      update_devices: "Update devices",
      knowledge_base: "Knowledge base",
      complementary_tests: "Complementary tests",
      complementary_tests_count:
        "{enabledCount} enabled, {disabledCount} disabled",
      picture_import: "Picture import",
      enabled: "Enabled",
      disabled: "Disabled",
      selective_reporting: "Selective reporting",
      linked_devices: "Linked devices",
      linked_devices_count:
        "no linked devices | 1 linked device | {count} linked devices",
      out_of_date_linked_devices_count: "{count} out of date",
      edit: "Edit",
      version: "Version v{version} - {date}",
      last_edited_selective_reporting: "Last edited {date}",
      picture_import_page: {
        picture_import: "Picture import",
        question:
          "Choose if when creating a new AST, your users should be able to import a picture from their device gallery or should be restricted to only directly take a picture.",
        answer_enable: "Allow picture import from device gallery",
        answer_disable: "Don’t allow picture import"
      },
      knowledge_base_page: {
        title: "Knowledge base",
        question:
          "Select the reference knowledge base that the app will use to interpret and determine S/I/R (Susceptible, Intermediate, Resistant)."
      },
      linked_devices_page: {
        linked_devices_title: "Linked devices",
        link_each_of_your_lab_devices_to_make_sure_they_get_updated:
          "Link each of your lab devices to make sure they get updated with changes you have made.",
        devices_count: "No device | 1 device | {count} devices",
        add_device: "Add device",
        device_name: "Device {name}",
        device_version: "v{version}",
        up_to_date: "Up to date",
        out_of_date: "Out of date",
        remove_device: "Remove",
        firestore: "Firestore",
        updated: "updated {date}"
      },
      complementary_tests_page: {
        title: "Complementary tests",
        description:
          "Confirm the complementary tests to be performed in your lab. When needed for AST interpretation, selected tests will appear as questions in the app.",
        carbapenemase_confirmation_test: "Carbapenemase confirmation test",
        production_of_carbapenemase: "Carbapenemase production"
      },
      selective_reporting_page: {
        title: "Selective reporting",
        question:
          "Upload a prescription protocol that will be used in results display. Start using MSF prescription protocol or start from a blank one.",
        upload_prescription_protocol: "Upload prescription protocol (.csv)",
        answer_yes_upload_selective_reporting: "Upload selective reporting",
        download: "Download",
        upload_new_and_replace: "Upload new and replace",
        remove: "Remove",
        uploaded_on: "Uploaded on {date}",
        prescription_protocol_in_use: "Prescription protocol in use",
        upload_error_title:
          "Upload rejected. Try solving the following issues:",
        upload_error_tips_message:
          "All “BacteriaSpecies” or “BacteriaGenus” fields must be filled.\n" +
          "All “Sample type” fields must be filled.\n" +
          "All “Antibiotic” fields must be filled.\n" +
          "All “Population” fields must be filled.\n" +
          "All “RouteOfAdministration” fields must be filled.\n" +
          "All “Priority” fields must be filled."
      }
    },
    global: {
      logout: "Log out",
      no_access: "No access",
      you_don_t_have_access_to_the_back_office:
        "You don’t have access to the back office. If you think this is an error, contact your admin.",
      back: "Back",
      save: "Save",
      loading: "Loading"
    },
    a11y: {
      qrcode_alt: "QR code"
    },
    snackbar: {
      error: {
        cannot_create_lab_with_no_name:
          "Sorry. We were unable to create a lab with no name.",
        cannot_create_new_lab:
          "Sorry. Something went wrong. We were unable to create this new lab.",
        cannot_retrieve_labs:
          "Sorry. Something went wrong and we were not able to retrieve labs.",
        cannot_retrieve_lab_infos:
          "Sorry. Something went wrong and we were not able to retrieve this lab's informations.",
        cannot_send_configuration:
          "Sorry. Something went wrong and we were not able to send new configuration to devices.",
        cannot_logout: "Sorry. Something went wrong while trying to logout.",
        cannot_login: "Sorry. Something went wrong while trying to log in.",
        cannot_save_lab_configuration_change:
          "Sorry. Something went wrong and we were not able to save this configuration change.",
        cannot_save_complementary_tests:
          "Sorry. Something went wrong while trying to save complementary tests status",
        generic_message_contact_admin:
          "Sorry. Something went wrong... Please contact your administrator.",
        no_complete_ast_found:
          "No complete AST was found to export from your search."
      },
      information: {
        configuration_sent_to_linked_devices:
          "Configuration sent to linked devices.",
        lab_added: "{name} added.",
        device_removed: "Device removed."
      },
      undo: "Undo"
    },
    ast_export: {
      title: "AST export",
      subtitle:
        "Export AST reports and logs from complete AST using their AST ID or hash.",
      app_version: "App version",
      build_type: "Build type",
      ast_ids: "AST IDs",
      hashes: "Hashes",
      export: "Export",
      several_ast_at_once_tips:
        "Export several AST at once by separating IDs with a comma.",
      app_version_required: "App version is required",
      ast_or_hash_required: "Ast ID or Hash is required",
      search_by_ast_id_or_hash: "Search by AST ID or Hash",
      results: {
        title: "AST export results",
        received_asts: "Records received for AST hashes : ",
        couldn_t_fetch_data_for_asts: "Couldn't fetch data for AST hashes : ",
        export_report: "Open AST report in Drive",
        export_logs: "Download AST logs (.json)",
        export_redcap_ast: "Download Redcap AST export (.csv)",
        export_notes_messages: "Download Notes, Messages and Alerts (.csv)",
        spreadsheet_unavailable: "Unable to generate AST Report (Google Sheet)",
        ast_logs_unavailable: "Unable to generate AST logs (.json)",
        redcap_ast_export_unavailable:
          "Unable to generate  Redcap AST export (.csv)",
        ast_id_info: "AST id : {ast_id} ",
        warning_refresh_page_message:
          "Current results will be lost if you refresh this page. Are you sure you want to continue ?"
      }
    }
  }
};

export default messages;
