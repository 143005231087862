import { firebaseApp, SCOPES } from "../firebase";
import firebase from "firebase/app";

export default {
  auth: firebaseApp.auth(),
  async login() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope(SCOPES);
    await firebaseApp.auth().signInWithPopup(provider);
  },
  async logout() {
    await firebaseApp.auth().signOut();
  }
};
