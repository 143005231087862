import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: {}
    },
    currentLabName: "",
    redirectionRouteAfterSuccessLogin: "",
    snackbar: {
      id: 0,
      text: ""
    },
    astResults: null
  },
  getters: {
    user: state => {
      return state.user;
    },
    currentLabName: state => {
      return state.currentLabName;
    },
    redirectionRouteAfterSuccessLogin: state => {
      return state.redirectionRouteAfterSuccessLogin;
    },
    snackbarContentText: state => {
      return state.snackbar.text;
    },
    astResults: state => {
      return state.astResults;
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
      if (data === null) {
        state.user.loggedIn = false;
      }
    },
    SET_CURRENT_LAB_NAME(state, value) {
      state.currentLabName = value.currentLabName;
    },
    SET_REDIRECTION_ROUTE_AFTER_SUCCESS_LOGIN(state, value) {
      state.redirectionRouteAfterSuccessLogin =
        value.redirectionRouteAfterSuccessLogin;
    },
    SET_SNACKBAR(state, value) {
      state.snackbar = {
        id: state.snackbar.id++,
        text: value
      };
    },
    SET_AST_RESULTS(state, value) {
      state.astResults = value;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    },
    setCurrentLabName({ commit }, currentLabName) {
      if (currentLabName !== undefined) {
        commit("SET_CURRENT_LAB_NAME", {
          currentLabName: currentLabName
        });
      }
    },
    resetCurrentLabName({ commit }) {
      commit("SET_CURRENT_LAB_NAME", {
        currentLabName: ""
      });
    },
    setRedirectionRouteAfterSuccessLogin(
      { commit },
      redirectionRouteAfterSuccessLogin
    ) {
      if (redirectionRouteAfterSuccessLogin !== undefined) {
        commit("SET_REDIRECTION_ROUTE_AFTER_SUCCESS_LOGIN", {
          redirectionRouteAfterSuccessLogin: redirectionRouteAfterSuccessLogin
        });
      }
    },
    resetRedirectionRoute({ commit }) {
      commit("SET_REDIRECTION_ROUTE_AFTER_SUCCESS_LOGIN", {
        redirectionRouteAfterSuccessLogin: ""
      });
    },
    setSnackbar({ commit }, snackbarContentText) {
      commit("SET_SNACKBAR", snackbarContentText);
    },
    setAstResults({ commit }, astResults) {
      commit("SET_AST_RESULTS", astResults);
    }
  },
  modules: {}
});
