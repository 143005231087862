import { render, staticRenderFns } from "./Drawer.component.html?vue&type=template&id=00d39918&scoped=true&"
import script from "./Drawer.component.ts?vue&type=script&lang=ts&"
export * from "./Drawer.component.ts?vue&type=script&lang=ts&"
import style0 from "./Drawer.component.scss?vue&type=style&index=0&id=00d39918&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d39918",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle})
