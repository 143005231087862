/* Header or each column in redcap export.
 * Be careful, orders in this enum matters. It matches order of column in the export.
 */
export enum RedcapColumn {
  record_id = "record_id",
  redcap_repeat_instrument = "redcap_repeat_instrument",
  redcap_repeat_instance = "redcap_repeat_instance",
  patient_specimen_abo = "patient_specimen_abo",
  bacteria_ident_abo = "bacteria_ident_abo",
  ast_datecreate_abo = "ast_datecreate_abo",
  picture_abo = "picture_abo",
  study_id_abo = "study_id_abo",
  table_resistance_mech_abo___1 = "table_resistance_mech_abo___1",
  table_resistance_mech_abo___2 = "table_resistance_mech_abo___2",
  table_resistance_mech_abo___3 = "table_resistance_mech_abo___3",
  table_resistance_mech_abo___4 = "table_resistance_mech_abo___4",
  table_resistance_mech_abo___5 = "table_resistance_mech_abo___5",
  table_resistance_mech_abo___6 = "table_resistance_mech_abo___6",
  table_resistance_mech_abo___7 = "table_resistance_mech_abo___7",
  table_resistance_mech_abo___8 = "table_resistance_mech_abo___8",
  table_resistance_mech_abo___9 = "table_resistance_mech_abo___9",
  table_resistance_mech_abo___10 = "table_resistance_mech_abo___10",
  table_resistance_mech_abo___11 = "table_resistance_mech_abo___11",
  table_resistance_mech_abo___12 = "table_resistance_mech_abo___12",
  table_resistance_mech_abo___13 = "table_resistance_mech_abo___13",
  table_resistance_mech_abo___14 = "table_resistance_mech_abo___14",
  table_resistance_mech_abo___15 = "table_resistance_mech_abo___15",
  table_resistance_mech_other_abo = "table_resistance_mech_other_abo",
  antibiogo_automatic_upload_bacteria_and_picture_complete = "antibiogo_automatic_upload_bacteria_and_picture_complete",
  antibiotic_name_abo = "antibiotic_name_abo",
  antibiotic_code_abo = "antibiotic_code_abo",
  izdinitial_abo = "izdinitial_abo",
  izdadjust_abo = "izdadjust_abo",
  gross_abo = "gross_abo",
  final_abo = "final_abo",
  antibiogo_automatic_upload_antibiotic_table_complete = "antibiogo_automatic_upload_antibiotic_table_complete",
  antibiotic_name_mic_abo = "antibiotic_name_mic_abo",
  antibiotic_code_mic_abo = "antibiotic_code_mic_abo",
  mic_val_abo = "mic_val_abo",
  final_sirmic_abo = "final_sirmic_abo",
  antibiogo_mic_complete = "antibiogo_mic_complete",
  mic_requested_by_antibiogo = "mic_requested_by_antibiogo",
  test_id_1_asked = "test_id_1_asked",
  test_id_1_answer = "test_id_1_answer",
  test_id_2_asked = "test_id_1_asked",
  test_id_2_answer = "test_id_1_answer",
  test_id_4_asked = "test_id_1_asked",
  test_id_4_answer = "test_id_1_answer",
  test_id_7_asked = "test_id_1_asked",
  test_id_7_answer = "test_id_1_answer",
  test_id_8_asked = "test_id_1_asked",
  test_id_8_answer = "test_id_1_answer",
  test_id_10_asked = "test_id_1_asked",
  test_id_10_answer = "test_id_1_answer",
  test_id_14_asked = "test_id_1_answer",
  test_id_14_answer = "test_id_1_answer",
  test_id_16_asked = "test_id_1_answer",
  test_id_16_answer = "test_id_1_answer",
  test_id_21_asked = "test_id_1_asked",
  test_id_21_answer = "test_id_1_asked",
  test_id_22_asked = "test_id_1_answer",
  test_id_22_answer = "test_id_1_answer",
  test_id_23_asked = "test_id_1_asked",
  test_id_23_answer = "test_id_1_answer",
  test_id_24_asked = "test_id_1_asked",
  test_id_24_answer = "test_id_1_answer",
  test_id_26_asked = "test_id_1_asked",
  test_id_26_answer = "test_id_1_answer",
  test_id_28_asked = "test_id_1_asked",
  test_id_28_answer = "test_id_1_answer",
  test_id_31_asked = "test_id_1_asked",
  test_id_31_answer = "test_id_1_answer",
  test_id_32_asked = "test_id_1_asked",
  test_id_32_answer = "test_id_1_answer"
}
