import { db } from "@/firebase";
import { sha256 } from "js-sha256";
// @ts-ignore
import { logsToAsts } from "./model/ast";
// @ts-ignore
import { generateAstSheet } from "./googlesheet/sheetCreation";
import { Ast, ASTResult } from "@/model/ASTResult";

declare var gapi: any;

export default {
  async getAstExport(
    astIds: Array<string>,
    hashes: Array<string>,
    appTrack: string,
    buildType: string,
    gapi: any
  ): Promise<ASTResult> {
    const astsToSearch = new Array<Ast>();
    hashes.forEach(hash => {
      astsToSearch.push(new Ast(null, hash));
    });
    astIds.forEach(id => {
      astsToSearch.push(new Ast(id, sha256(id)));
    });
    let astLogs: any = [];
    let astResult: ASTResult = new ASTResult(
      new Array<Ast>(),
      new Array<Ast>(),
      [],
      []
    );

    try {
      // 1. retrieve all AST
      for await (const singleAst of astsToSearch) {
        const entries = await db
          .collection("astLogs")
          .doc(appTrack)
          .collection(buildType)
          .where("astIdHash", "==", singleAst.hash)
          .orderBy("timestampMs", "desc")
          .get();

        if (entries.size === 0) {
          console.error(`Couldn't fetch data for AST hash ${singleAst.hash}`);
          astResult.notFound.push(singleAst);
        }
        entries.forEach(entry => astLogs.push(entry.data()));

        // Add as many as singleAst to astResult.found regarding duplicate id in logs
        const duplicateCount = new Set(
          astLogs
            .filter((log: any) => log.astIdHash === singleAst.hash)
            .map((log: any) => log.sessionId)
        ).size;
        for (let i = 0; i < duplicateCount; i++) {
          astResult.found.push(singleAst);
        }
      }
      astResult.astLogs = astLogs;

      // 2. generate ASTs Report
      const foundASTIds = astResult.found
        .map(ast => ast.id)
        .filter(astId => astId !== null);
      let astsReport = logsToAsts(astLogs, foundASTIds).filter(
        (ast: any) => ast.complete
      ); // filter out ASTs that are not complete
      if (!astsReport.length) {
        return Promise.reject(Error("No complete AST"));
      }
      astResult.astsReport = astsReport;
      return Promise.resolve(astResult);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
  async generateAstGoogleSheet(
    astResult: ASTResult,
    gapi: any
  ): Promise<string> {
    // 3. generate AST google sheet from ASTs report
    let sheets = await Promise.all(
      astResult.astsReport.map((ast: any, index: any) =>
        generateAstSheet(ast, index)
      )
    );

    // 4. Create spreadsheet
    const spreadsheetName = this.getExportTitle(astResult.found);
    if (astResult.found.length > 0) {
      const gapiClient = await gapi.getGapiClient();
      const response = await gapiClient.client.sheets.spreadsheets.create({
        properties: {
          title: spreadsheetName
        },
        sheets
      });
      return Promise.resolve(response.result.spreadsheetUrl.toString());
    } else {
      throw Error("no ast found in ASTResult");
    }
  },
  getExportTitle(asts: Ast[]) {
    return asts
      .map((ast: Ast) => {
        let astIdentifier = "";
        if (ast.id !== null) {
          astIdentifier = ast.id;
        } else {
          astIdentifier = ast.hash;
        }
        return astIdentifier;
      })
      .join("-");
  }
};
