export class NotesAndMessagesRow {
  public constructor(
    private readonly astId: string,
    private readonly labSheet: string,
    private readonly resultSheet: string,
    private readonly messages: string,
    private readonly ipcAlerts: string
  ) {}
}

export const emptyNotesAndMessagesRow = new NotesAndMessagesRow(
  "",
  "",
  "",
  "",
  ""
);
