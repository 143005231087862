import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import store from "@/store";
import router from "@/router";

@Component({})
export default class PictureImportComponent extends Vue {
  // data
  pictureImportStatusInitialValue = "";
  lab: any = {};
  labId = this.$route.params.labId;
  pictureImportStatus = "";

  // view lifecycle
  mounted() {
    this.getLab(this.labId);
  }

  private async getLab(labId: string) {
    try {
      const currentLab = await labsCollection.getLab(labId);
      this.lab = currentLab;
      store.dispatch("setCurrentLabName", currentLab.name);
      this.pictureImportStatusInitialValue = this.importPictureBooleanToStringValue(
        currentLab.config.draftConfig.importPictureEnabled
      );
      this.pictureImportStatus = this.pictureImportStatusInitialValue;
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_retrieve_lab_infos")
      );
    }
  }

  // user actions
  private clickOnBack() {
    router.back();
  }

  private async clickOnSave() {
    const newImportPictureValue = this.importPictureStringValueToBoolean(
      this.pictureImportStatus
    );
    if (newImportPictureValue === undefined) {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
      return;
    }

    try {
      await labsCollection.updateLabImportPicture(
        this.labId,
        newImportPictureValue
      );
      this.$router.back();
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_save_lab_configuration_change")
      );
    }
  }

  //Utils methods
  private importPictureBooleanToStringValue(importPicture: boolean): string {
    switch (importPicture) {
      case true: {
        return "enabled";
        break;
      }
      case false: {
        return "disabled";
        break;
      }
      default: {
        return "";
        break;
      }
    }
  }

  private importPictureStringValueToBoolean(
    importPictureString: string
  ): boolean | undefined {
    switch (importPictureString) {
      case "enabled": {
        return true;
        break;
      }
      case "disabled": {
        return false;
        break;
      }
      default: {
        return undefined;
        break;
      }
    }
  }

  private configHasNotChanged(): boolean {
    return (
      this.pictureImportStatus === undefined ||
      this.pictureImportStatus === this.pictureImportStatusInitialValue
    );
  }
}
