import { db } from "@/firebase";

export default {
  async isUserExist(email: string): Promise<boolean> {
    try {
      const user = await db
        .collection("users")
        .doc(email)
        .get();
      return user.exists;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }
};
