import { RedcapColumn } from "@/domain/redcap/model/RedcapColumn";
import * as RedcapConstValue from "@/domain/redcap/constants/RedcapConstValue";
import {
  extractTestsFromAST,
  formationCreationDate,
  getDDSir,
  getDDSiri,
  getMicSiri,
  restoreFullName
} from "../model/ast";
import { SIRIMappingEUCAST } from "../siri/SIRIMappingEUCAST";
import { SIRIMappingCLSI } from "../siri/SIRIMappingCLSI";
import utils from "@/domain/utils";
import { SIRIValue } from "@/domain/siri/SIRIValue";
import { redcapComplementaryTests } from "@/domain/redcap/model/RedcapComplementaryTest";
import { redcapComplexMechanisms } from "@/domain/redcap/model/RedcapComplexMechanism";
import { ResistanceMechanismExtractor } from "@/domain/ResistanceMechanismExtractor";

export default {
  async generateExportData(asts: Array<any>): Promise<Array<any>> {
    let resultExport = new Array<any>();
    // 1. Header
    const headerRow = this.generateHeaderRow();
    resultExport.push(headerRow);

    await Promise.all(
      asts.map(async ast => {
        // 2. Metadata
        const metadataMap = await this.generateMetadataMap(ast);
        const metadataRow = this.generateRowForMap(metadataMap);
        resultExport.push(metadataRow);

        // 3. Antibiotics
        ast.finalAntibiotics?.forEach((finAb: any, index: number) => {
          const restoredFullName = restoreFullName(ast, finAb.fullName);
          const antibioticInAntiBiogram =
            ast.finalAntibiogram.antibiotics[restoredFullName];
          let abMap = this.generateAntibioticsMap(
            ast.astId,
            ast.knowledgeBase,
            antibioticInAntiBiogram,
            finAb,
            this.findInitAb(ast, finAb),
            ast.micsRequestedByAntibiogo,
            index + 1
          );
          const abRow = this.generateRowForMap(abMap);
          resultExport.push(abRow);
        });

        // 4. MIC
        let index = 0;
        ast.micAntibiotics?.forEach((mic: any) => {
          const restoredFullName = restoreFullName(ast, mic.fullName);
          const micInAntibiogram =
            ast.finalAntibiogram.antibiotics[restoredFullName];
          const micMap = this.generateMicMap(
            ast.astId,
            ast.knowledgeBase,
            micInAntibiogram,
            mic,
            ast.micsRequestedByAntibiogo,
            index + 1
          );
          const micRow = this.generateRowForMap(micMap);
          resultExport.push(micRow);
          index++;
        });

        // 5. Add MIC that has been requested by Antibiogo but have no micValue yes
        ast.micsRequestedByAntibiogo.forEach((micRequested: any) => {
          if (
            ast.micAntibiotics.find(
              (mic: any) => mic.fullName === micRequested
            ) === undefined
          ) {
            const requestedMicNa = {
              fullName: micRequested,
              micValue: "na"
            };
            const micMap = this.generateEmptyRequestedMicMap(
              ast.astId,
              requestedMicNa,
              index + 1
            );
            const micRow = this.generateRowForMap(micMap);
            resultExport.push(micRow);
            index++;
          }
        });
      })
    );

    return resultExport;
  },

  generateHeaderRow(): Array<string> {
    const headerRow = new Array<string>();
    for (const key in RedcapColumn) {
      headerRow.push(key.toString());
    }
    return headerRow;
  },

  generateRowForMap(map: Map<String, any>): Array<string> {
    const row = new Array<string>();
    for (const key in RedcapColumn) {
      if (map.has(key)) {
        row.push(map.get(key));
      } else {
        row.push("");
      }
    }
    return row;
  },

  getImageUrl: async function(ast: any) {
    try {
      return await ast.getImageUrlPromise();
    } catch (e) {
      return "NOT FOUND";
    }
  },

  generateBasicInfoMap: async function(ast: any) {
    const basicInfoMap = new Map<String, any>();
    if (ast.astId !== undefined) {
      basicInfoMap.set(RedcapColumn.record_id, ast.astId);
      basicInfoMap.set(RedcapColumn.study_id_abo, ast.astId);
    }
    if (ast.sampleType !== undefined) {
      basicInfoMap.set(RedcapColumn.patient_specimen_abo, ast.sampleType);
    }
    if (ast.species !== undefined) {
      basicInfoMap.set(RedcapColumn.bacteria_ident_abo, ast.species);
    }
    if (ast.startTime !== undefined) {
      basicInfoMap.set(
        RedcapColumn.ast_datecreate_abo,
        formationCreationDate(ast.startTime)
      );
    }
    basicInfoMap.set(
      RedcapColumn.antibiogo_automatic_upload_bacteria_and_picture_complete,
      RedcapConstValue.complete
    );
    basicInfoMap.set(RedcapColumn.picture_abo, await this.getImageUrl(ast));
    return basicInfoMap;
  },

  generateComplementaryTestMap(ast: any) {
    const map = new Map<String, any>();
    const testsInAst = extractTestsFromAST(ast);
    redcapComplementaryTests.forEach(complementaryTest => {
      const id = complementaryTest.id;
      const name = complementaryTest.name.trim().toLowerCase();
      const answers = complementaryTest.answers;
      const askedRedcapColumnName = `test_id_${id}_asked`;
      const answerRedcapColumnName = `test_id_${id}_answer`;
      if (testsInAst.has(name)) {
        const testAnswer = testsInAst.get(name);
        const answer = answers.find(
          cta => cta.value.trim().toLowerCase() === testAnswer
        );
        const answerRedcapValue = answer?.redcapValue ?? null;
        map.set(askedRedcapColumnName, 1);
        map.set(answerRedcapColumnName, answerRedcapValue);
      } else {
        map.set(askedRedcapColumnName, 0);
      }
    });
    return map;
  },

  async generateMetadataMap(ast: any): Promise<Map<String, any>> {
    return Promise.resolve(
      new Map([
        ...(await this.generateBasicInfoMap(ast)).entries(),
        ...this.generateComplexMechanismMap(ast).entries(),
        ...this.generateComplementaryTestMap(ast).entries()
      ])
    );
  },

  generateComplexMechanismMap(ast: any): Map<string, number> {
    const resistanceMechanismsIndAST = ResistanceMechanismExtractor.getResistanceMechanismsFor(
      ast
    );
    const mechanismMap = new Map<string, any>();
    mechanismMap.set(
      RedcapColumn.table_resistance_mech_abo___1,
      Number(resistanceMechanismsIndAST.size === 0)
    );
    redcapComplexMechanisms.forEach(complexMechanism =>
      mechanismMap.set(
        `table_resistance_mech_abo___${complexMechanism.id}`,
        Number(
          complexMechanism.resistanceMechanisms.some(resistanceMechanism =>
            resistanceMechanismsIndAST.has(resistanceMechanism)
          )
        )
      )
    );
    return mechanismMap;
  },

  generateAntibioticsMap(
    astId: string,
    knowledgeBase: string,
    abInAntibiogram: any,
    finalAb: any,
    initAb: any,
    micsRequestedByAntibiogo: Set<string>,
    index: number
  ): Map<String, any> {
    const antibioticMap = new Map<String, any>();
    const finalIZD = Math.round(finalAb.inhibition.diameterMillimeter);
    const initIZD =
      initAb && initAb.diamMm ? Math.round(initAb.diamMm) : finalIZD;
    const ddSir = getDDSir(abInAntibiogram);
    const ddSiri = getDDSiri(abInAntibiogram);
    antibioticMap.set(RedcapColumn.record_id, astId);
    antibioticMap.set(
      RedcapColumn.redcap_repeat_instrument,
      RedcapConstValue.antibiogo_automatic_upload_antibiotic_table
    );
    antibioticMap.set(RedcapColumn.redcap_repeat_instance, index);
    antibioticMap.set(RedcapColumn.antibiotic_name_abo, finalAb.fullName);
    antibioticMap.set(RedcapColumn.antibiotic_code_abo, finalAb.label.text);
    antibioticMap.set(RedcapColumn.izdinitial_abo, initIZD);
    antibioticMap.set(RedcapColumn.izdadjust_abo, finalIZD);
    antibioticMap.set(
      RedcapColumn.gross_abo,
      this.mapSirOrSiriKeyToRedcapKey(ddSir, knowledgeBase)
    );
    antibioticMap.set(
      RedcapColumn.final_abo,
      this.mapSirOrSiriKeyToRedcapKey(ddSiri, knowledgeBase)
    );
    antibioticMap.set(
      RedcapColumn.antibiogo_automatic_upload_antibiotic_table_complete,
      RedcapConstValue.complete
    );
    return antibioticMap;
  },

  generateMicMap(
    astId: string,
    knowledgeBase: string,
    micInAntibiogram: any,
    mic: any,
    micsRequestedByAntibiogo: Set<string>,
    index: number
  ): Map<string, any> {
    const micMap = new Map<string, any>();
    micMap.set(RedcapColumn.record_id, astId);
    micMap.set(
      RedcapColumn.redcap_repeat_instrument,
      RedcapConstValue.antibiogo_mic
    );
    micMap.set(RedcapColumn.redcap_repeat_instance, index);
    micMap.set(RedcapColumn.antibiotic_name_mic_abo, mic.fullName);
    micMap.set(RedcapColumn.mic_val_abo, mic.micValue);
    micMap.set(
      RedcapColumn.final_sirmic_abo,
      this.mapSirOrSiriKeyToRedcapKey(
        getMicSiri(micInAntibiogram),
        knowledgeBase
      )
    );
    micMap.set(RedcapColumn.antibiogo_mic_complete, RedcapConstValue.complete);
    micMap.set(
      RedcapColumn.mic_requested_by_antibiogo,
      micsRequestedByAntibiogo.has(mic.fullName) ? 1 : 0
    );
    return micMap;
  },

  generateEmptyRequestedMicMap(
    astId: string,
    mic: any,
    index: number
  ): Map<string, any> {
    const micMap = new Map<string, any>();
    micMap.set(RedcapColumn.record_id, astId);
    micMap.set(
      RedcapColumn.redcap_repeat_instrument,
      RedcapConstValue.antibiogo_mic
    );
    micMap.set(RedcapColumn.redcap_repeat_instance, index);
    micMap.set(RedcapColumn.antibiotic_name_mic_abo, mic.fullName);
    micMap.set(RedcapColumn.mic_val_abo, mic.micValue);
    micMap.set(RedcapColumn.mic_requested_by_antibiogo, 1);
    return micMap;
  },

  mapSirOrSiriKeyToRedcapKey(sirOrSiri: string, knowledgeBase: string): string {
    let siriEntry: SIRIValue | undefined = undefined;
    if (utils.isKnowledgeBaseEUCAST(knowledgeBase)) {
      siriEntry = SIRIMappingEUCAST.get(sirOrSiri);
    } else if (utils.isKnowledgeBaseCLSI(knowledgeBase)) {
      siriEntry = SIRIMappingCLSI.get(sirOrSiri);
    }
    if (siriEntry !== undefined) {
      return siriEntry.redcapValue;
    } else {
      // no code found
      return sirOrSiri?.replace(",", " ");
    }
  },

  findInitAb(ast: any, finAb: any) {
    let foundInitAb, foundEditedIzd;
    const id = finAb.id;
    if (id) {
      foundInitAb = ast.initialAntibiotics.find(
        (iab: { id: string }) => iab.id === id
      );
      foundEditedIzd = ast.userEditedIzds.find(
        (izd: { pellet: { id: string } }) => izd.pellet.id === id
      );
    }
    return foundInitAb
      ? foundInitAb
      : foundEditedIzd
      ? foundEditedIzd.pellet
      : {};
  }
};
