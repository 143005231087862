import {
  formationCreationDate,
  getDDSir,
  getDDSiri,
  getMicSiri,
  restoreFullName
} from "../model/ast";
import { Cell } from "@/domain/googlesheet/Cell";
import { SIRIMappingEUCAST } from "../siri/SIRIMappingEUCAST";
import { SIRIMappingCLSI } from "../siri/SIRIMappingCLSI";
import utils from "@/domain/utils";
import { ResistanceMechanismExtractor } from "@/domain/ResistanceMechanismExtractor";

/* Functions to create the spreadsheet exported by export.html */

const sheetFontFamily = "Rubik";

function colorToFraction(colors: any): any {
  return {
    red: colors.red / 255,
    green: colors.green / 255,
    blue: colors.blue / 255,
    alpha: colors.alpha
  };
}

function stringCell(s: any, type: any = undefined): any {
  if (s === "" && type && type === "emptyValue") {
    return {
      userEnteredFormat: {
        horizontalAlignment: "CENTER",
        textFormat: {
          fontFamily: sheetFontFamily
        }
      }
    };
  }

  let cellHorizontalAlignment = "CENTER";

  if (type && type === "astInfoValue") {
    cellHorizontalAlignment = "LEFT";
  }

  let cell = new Cell(
    {
      stringValue: s
    },
    {
      horizontalAlignment: cellHorizontalAlignment,
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  );

  if (type && type.match(/[Hh]eader/)) {
    let fontWeightBold = false;
    cellHorizontalAlignment = "LEFT";

    if (type === "atbColumnHeader") {
      cellHorizontalAlignment = "CENTER";
    }

    if (type === "atbShortNameHeader") {
      fontWeightBold = true;
    }

    cell.userEnteredFormat = {
      backgroundColor: colorToFraction({
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1
      }),
      horizontalAlignment: cellHorizontalAlignment,
      verticalAlignment: "MIDDLE",
      textFormat: {
        bold: fontWeightBold,
        fontFamily: sheetFontFamily,
        foregroundColor: colorToFraction({
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        })
      },
      wrapStrategy: "WRAP"
    };
  }

  return cell;
}

function formulaCell(
  f: any,
  rowIndex: any,
  horizontalAlignment = "CENTER"
): any {
  let row = rowIndex + 1;

  return {
    userEnteredValue: {
      formulaValue: f.replaceAll("%ROW", row)
    },
    userEnteredFormat: {
      horizontalAlignment: horizontalAlignment,
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };
}

function checkBoxCell(b: any): any {
  return {
    userEnteredValue: {
      boolValue: b
    },
    dataValidation: {
      condition: { type: "BOOLEAN" },
      strict: true
    },
    userEnteredFormat: {
      horizontalAlignment: "CENTER",
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };
}

function numberCell(n: any): any {
  return {
    userEnteredValue: {
      numberValue: n
    },
    userEnteredFormat: {
      horizontalAlignment: "CENTER",
      textFormat: {
        fontFamily: sheetFontFamily
      }
    }
  };
}

export async function generateAstSheet(ast: any, sheetId: any) {
  if (!ast.complete) {
    throw Error("Incomplete AST");
  }

  let rowIndex = 0;
  let dataGrid: Array<any> = [];

  function pushToDataGrid(rows: any, hiddenColumns: any = undefined) {
    let maxEditRow = rowIndex + rows.length;
    let numberOfColumns = Math.max(
      ...rows.map((row: any) => row.values.length)
    );
    let columnMetadata = [];
    for (let i = 0; i < numberOfColumns; i++) {
      columnMetadata.push({
        pixelSize: 150,
        hiddenByUser: hiddenColumns && hiddenColumns.includes(i)
      });
    }
    dataGrid.push({
      startRow: rowIndex,
      startColumn: 0,
      rowData: rows,
      columnMetadata
    });

    rowIndex = Math.max(rowIndex, maxEditRow);
  }

  // add ast creation date
  pushToDataGrid([
    {
      values: [
        stringCell("AST Creation Date", "header"),
        stringCell(formationCreationDate(ast.startTime), "astInfoValue")
      ]
    }
  ]);

  // add app version
  pushToDataGrid([
    {
      values: [
        stringCell("App version", "header"),
        stringCell(ast.appVersion, "astInfoValue")
      ]
    }
  ]);

  // add bacteria species
  pushToDataGrid([
    {
      values: [
        stringCell("Species", "header"),
        stringCell(ast.species, "astInfoValue")
      ]
    }
  ]);

  // add sample type
  pushToDataGrid([
    {
      values: [
        stringCell("Sample type", "header"),
        stringCell(ast.sampleType, "astInfoValue")
      ]
    }
  ]);

  let imageUrl: string;
  try {
    imageUrl = await ast.getImageUrlPromise();
  } catch (e) {
    console.error(e);
    imageUrl = "NOT FOUND";
  }
  let imageUrlToText = imageUrl.replace('"', '\\"');
  let imageFormulaTemplate = '=LIEN_HYPERTEXTE("imageUrl";"imageUrlToText")';
  let imageFormula = imageFormulaTemplate
    .replace("imageUrl", imageUrl)
    .replace("imageUrlToText", imageUrlToText);
  // add picture link and empty line
  pushToDataGrid([
    {
      values: [
        stringCell("Image", "header"),
        formulaCell(imageFormula, "astInfoValue", "LEFT")
      ]
    },
    {
      values: [stringCell("")]
    }
  ]);

  const columnHeaders = [
    "",
    "",
    "Antibiogo diameters",
    "Manual diameters",
    "Diameters comparison",
    "Gross categorization",
    "Interpretive reading Expert System",
    "Interpretive reading Microbiologist",
    "Interpretive reading comparison"
  ];

  // add header for each columns
  pushToDataGrid([
    {
      values: columnHeaders.map(value => stringCell(value, "atbColumnHeader"))
    }
  ]);

  function mapSirOrSiriKeyToSheetKey(sirOrSiri: any, knowledgeBase: string) {
    let siriEntryInMapping: any = undefined;
    if (utils.isKnowledgeBaseEUCAST(knowledgeBase)) {
      siriEntryInMapping = SIRIMappingEUCAST.get(sirOrSiri);
    } else if (utils.isKnowledgeBaseCLSI(knowledgeBase)) {
      siriEntryInMapping = SIRIMappingCLSI.get(sirOrSiri);
    }
    if (siriEntryInMapping !== undefined) {
      return siriEntryInMapping.sheetValue;
    } else {
      // no code found
      return sirOrSiri;
    }
  }

  ast.finalAntibiotics.forEach((ab: any) =>
    pushToDataGrid([
      {
        values: [
          stringCell(ab.fullName, "atbHeader"),
          stringCell(ab.label.text, "atbShortNameHeader"),
          numberCell(ab.inhibition.diameterMillimeter),
          stringCell("", "emptyValue" /* manual diameters */),
          formulaCell(
            '=SI(ESTVIDE(D%ROW),"",SI(C%ROW=D%ROW,"OK","KO"))' /* diameters comparison */,
            rowIndex
          ),
          stringCell(
            mapSirOrSiriKeyToSheetKey(
              getDDSir(
                ast.finalAntibiogram.antibiotics[
                  restoreFullName(ast, ab.fullName)
                ]
              ),
              ast.knowledgeBase
            )
          ),
          stringCell(
            mapSirOrSiriKeyToSheetKey(
              getDDSiri(
                ast.finalAntibiogram.antibiotics[
                  restoreFullName(ast, ab.fullName)
                ]
              ),
              ast.knowledgeBase
            )
          ),
          stringCell(
            "",
            "emptyValue" /* interpretive reading microbiologist */
          ),
          formulaCell(
            '=SI(ESTVIDE(H%ROW),"",SI(G%ROW=H%ROW,"OK","KO"))' /* interpretive reading comparison */,
            rowIndex
          )
        ]
      }
    ])
  );
  pushToDataGrid([
    {
      values: [stringCell("")]
    }
  ]);

  const micColumnHeaders = [
    "",
    "",
    "MIC value",
    "",
    "",
    "",
    "Interpretive reading Expert System",
    "Interpretive reading Microbiologist",
    "Interpretive reading comparison"
  ];

  // add header for each columns
  pushToDataGrid([
    {
      values: micColumnHeaders.map(value =>
        stringCell(value, "atbColumnHeader")
      )
    }
  ]);

  ast.micAntibiotics?.forEach((ab: any) =>
    pushToDataGrid([
      {
        values: [
          stringCell(ab.fullName, "atbHeader"),
          stringCell("", "atbHeader"),
          numberCell(ab.micValue),
          stringCell("", "emptyValue" /* manual diameters */),
          stringCell("", "emptyValue" /* manual diameters */),
          stringCell("", "emptyValue" /* manual diameters */),
          stringCell(
            mapSirOrSiriKeyToSheetKey(
              getMicSiri(
                ast.finalAntibiogram.antibiotics[
                  restoreFullName(ast, ab.fullName)
                ]
              ),
              ast.knowledgeBase
            )
          ),
          stringCell(
            "",
            "emptyValue" /* interpretive reading microbiologist */
          ),
          formulaCell(
            '=SI(ESTVIDE(H%ROW),"",SI(G%ROW=H%ROW,"OK","KO"))' /* interpretive reading comparison */,
            rowIndex
          )
        ]
      }
    ])
  );

  pushToDataGrid([
    {
      values: [stringCell("")]
    }
  ]);

  ResistanceMechanismExtractor.getResistanceMechanismsFor(ast).forEach(
    (mechanism: any) =>
      pushToDataGrid(
        [
          {
            values: [stringCell(mechanism[0], "mechanismHeader")].concat(
              ["", "", "", "", ""]
                .map(cellValue => stringCell(cellValue))
                .concat([mechanism[1], false].map(checkBoxCell))
            )
          }
        ],
        [6]
      )
  );

  const esColumnIndex = columnHeaders.indexOf(
    "Interpretive reading Expert System"
  );

  return {
    properties: {
      title: (ast.astId || ast.astIdHash) + " (" + sheetId + ")",
      sheetId
    },
    data: dataGrid,
    protectedRanges: [
      {
        range: {
          sheetId,
          // Protecting the expert system columns (which is also hidden)
          // as it shouldn't be visible to the microbiologist who will
          // fill the spreadsheet
          startColumnIndex: esColumnIndex,
          endColumnIndex: esColumnIndex + 1
        },
        warningOnly: false
      }
    ]
  };
}
