export class ComplementaryTest {
  key: string;
  status: boolean;
  answer: string;
  resourceKey: string;
  resourcePath: string;

  constructor(
    key: string,
    value: boolean,
    answer: string,
    resourceKey: string
  ) {
    this.key = key;
    this.status = value;
    this.answer = answer;
    this.resourceKey = resourceKey;
    this.resourcePath = "lab_home.complementary_tests_page." + this.resourceKey;
  }
}

export const allComplementaryTests = [
  new ComplementaryTest(
    "carbapenemase confirmation test",
    true,
    "Not performed",
    "carbapenemase_confirmation_test"
  ),
  new ComplementaryTest(
    "Production of carbapenemase",
    true,
    "Unknown",
    "production_of_carbapenemase"
  )
];
