import { ComplementaryTestConstants } from "@/domain/redcap/constants/ComplementaryTestConstants";

export class RedcapComplementaryTestAnswer {
  constructor(public value: string, public redcapValue: number) {}
}

export class RedcapComplementaryTest {
  constructor(
    public id: number,
    public name: string,
    public answers: RedcapComplementaryTestAnswer[]
  ) {}
}

export const redcapComplementaryTests: Array<RedcapComplementaryTest> = [
  {
    id: 1,
    name: ComplementaryTestConstants.ACINETOBACTER_BETA_LACTAMS,
    answers: [
      { value: ComplementaryTestConstants.UNKNOWN, redcapValue: 1 },
      { value: ComplementaryTestConstants.SUSCEPTIBLE, redcapValue: 2 },
      { value: ComplementaryTestConstants.PENICILLINASE, redcapValue: 3 },
      {
        value: ComplementaryTestConstants.LOW_LEVEL_CEPHALOSPORINASE,
        redcapValue: 4
      },
      { value: ComplementaryTestConstants.HL_CEPHALOSPORINASE, redcapValue: 5 },
      { value: ComplementaryTestConstants.PASE_LOW_LEVEL_CASE, redcapValue: 6 },
      { value: ComplementaryTestConstants.PASE_HIGH_LEVEL_CASE, redcapValue: 7 }
    ]
  },
  {
    id: 2,
    name: ComplementaryTestConstants.APPEARANCE_OF_INHIBITION_ZONE,
    answers: [
      { value: ComplementaryTestConstants.SHARP, redcapValue: 1 },
      { value: ComplementaryTestConstants.FUZZY, redcapValue: 2 },
      { value: ComplementaryTestConstants.UNCERTAIN, redcapValue: 3 }
    ]
  },
  {
    id: 4,
    name: ComplementaryTestConstants.CARBAPENEMASE_CONFIRMATION_TEST,
    answers: [
      { value: ComplementaryTestConstants.POSITIVE, redcapValue: 1 },
      { value: ComplementaryTestConstants.NEGATIVE, redcapValue: 2 },
      { value: ComplementaryTestConstants.NOT_PERFORMED, redcapValue: 3 },
      { value: ComplementaryTestConstants.IN_PROGRESS, redcapValue: 4 }
    ]
  },
  {
    id: 7,
    name: ComplementaryTestConstants.ENTEROBACTERIACCEAE_BLACTAMS,
    answers: [
      { value: ComplementaryTestConstants.WILD, redcapValue: 1 },
      {
        value: ComplementaryTestConstants.LOW_LEVEL_PENICILLINASE,
        redcapValue: 2
      },
      { value: ComplementaryTestConstants.HIGH_LEVEL_PASE, redcapValue: 3 },
      { value: ComplementaryTestConstants.CEPHALOSPORINASE, redcapValue: 4 },
      {
        value: ComplementaryTestConstants.CEPHALOSPORINASE_PNASE,
        redcapValue: 5
      },
      {
        value: ComplementaryTestConstants.HYPERPROD_OF_CEPHALOSPORINASE,
        redcapValue: 6
      },
      { value: ComplementaryTestConstants.ESBL, redcapValue: 7 },
      { value: ComplementaryTestConstants.UNKNOWN_PHENOTYPE, redcapValue: 8 },
      { value: ComplementaryTestConstants.IRT, redcapValue: 9 },
      {
        value: ComplementaryTestConstants.HYPER_PRODUCED_BETA_LACTAMASE,
        redcapValue: 10
      },
      { value: ComplementaryTestConstants.IRT_CASE, redcapValue: 11 },
      { value: ComplementaryTestConstants.ESBL_SUSPICION, redcapValue: 12 },
      { value: ComplementaryTestConstants.CHROMOSOMAL_ESBL, redcapValue: 13 },
      {
        value: ComplementaryTestConstants.ESBL_HIGH_LEVEL_CEPHALOSPORINASE,
        redcapValue: 14
      },
      {
        value: ComplementaryTestConstants.ESBL_SUSPICION_HIGH_LEVEL_CASE,
        redcapValue: 15
      },
      {
        value: ComplementaryTestConstants.ESBL_HIGH_LEVEL_B_LACTAMASE,
        redcapValue: 16
      },
      {
        value: ComplementaryTestConstants.PROBABLE_HYPERPRODUCING_B_LACTAMASE,
        redcapValue: 17
      },
      {
        value: ComplementaryTestConstants.HIGH_LEVEL_PASE_HYPERP,
        redcapValue: 18
      }
    ]
  },
  {
    id: 8,
    name: ComplementaryTestConstants.ENTEROCOCCI_COLONY_VANCO_TEICO,
    answers: [
      { value: ComplementaryTestConstants.NO, redcapValue: 1 },
      { value: ComplementaryTestConstants.YES, redcapValue: 2 }
    ]
  },
  {
    id: 10,
    name: ComplementaryTestConstants.ENTEROCOCCI_AND_GLYCOPEPTIDES,
    answers: [
      { value: ComplementaryTestConstants.SUSCEPTIBLE, redcapValue: 1 },
      { value: ComplementaryTestConstants.RESISTANT, redcapValue: 2 },
      { value: ComplementaryTestConstants.RESISTANT_BY_MIC, redcapValue: 3 }
    ]
  },
  {
    id: 14,
    name: ComplementaryTestConstants.HCASE_TYPE,
    answers: [
      { value: ComplementaryTestConstants.CHROMOSOMIC, redcapValue: 1 },
      { value: ComplementaryTestConstants.PLASMIDIC, redcapValue: 2 }
    ]
  },
  {
    id: 16,
    name: ComplementaryTestConstants.INDUCTION,
    answers: [
      { value: ComplementaryTestConstants.NO, redcapValue: 1 },
      { value: ComplementaryTestConstants.YES, redcapValue: 2 }
    ]
  },
  {
    id: 21,
    name: ComplementaryTestConstants.PASE_TYPE,
    answers: [
      { value: ComplementaryTestConstants.LOW_LEVEL_PASE, redcapValue: 1 },
      { value: ComplementaryTestConstants.HIGH_LEVEL_PASE, redcapValue: 2 },
      { value: ComplementaryTestConstants.CHROMOSOMIC_PASE, redcapValue: 3 },
      { value: ComplementaryTestConstants.PASE_TYPE_CASE, redcapValue: 4 },
      { value: ComplementaryTestConstants.NATURAL_PASE, redcapValue: 5 },
      { value: ComplementaryTestConstants.IRT, redcapValue: 6 },
      { value: ComplementaryTestConstants.IRT_PASE, redcapValue: 7 },
      {
        value: ComplementaryTestConstants.HIGH_LEVEL_PASE_OR_IRT,
        redcapValue: 8
      }
    ]
  },
  {
    id: 22,
    name: ComplementaryTestConstants.PENICILLINASE,
    answers: [
      { value: ComplementaryTestConstants.NEGATIVE, redcapValue: 1 },
      { value: ComplementaryTestConstants.POSITIVE, redcapValue: 2 },
      { value: ComplementaryTestConstants.NOT_PERFORMED, redcapValue: 3 },
      { value: ComplementaryTestConstants.IN_PROGRESS, redcapValue: 4 }
    ]
  },
  {
    id: 23,
    name: ComplementaryTestConstants.PNEUMOCOCCI_BLACTAMS,
    answers: [
      { value: ComplementaryTestConstants.SUSCEPTIBLE, redcapValue: 1 },
      { value: ComplementaryTestConstants.DIMISHED_SUSCEPT, redcapValue: 2 },
      { value: ComplementaryTestConstants.NOT_TESTED, redcapValue: 3 }
    ]
  },
  {
    id: 24,
    name: ComplementaryTestConstants.PRODUCTION_OF_CARBAPENEMASE,
    answers: [
      { value: ComplementaryTestConstants.YES, redcapValue: 1 },
      { value: ComplementaryTestConstants.NO, redcapValue: 2 },
      { value: ComplementaryTestConstants.SUSPICION, redcapValue: 3 },
      { value: ComplementaryTestConstants.UNKNOWN, redcapValue: 4 }
    ]
  },
  {
    id: 26,
    name: ComplementaryTestConstants.STAPH_MLS_PHENOTYPES,
    answers: [
      { value: ComplementaryTestConstants.NOT_AFFECTED, redcapValue: 1 },
      { value: ComplementaryTestConstants.NOT_DETERMINABLE, redcapValue: 2 },
      { value: ComplementaryTestConstants.SUSCEPTIBLE, redcapValue: 3 },
      {
        value: ComplementaryTestConstants.INDUCIBLE_MLSB_OR_EFFLUX,
        redcapValue: 4
      },
      { value: ComplementaryTestConstants.CONSTITUTIVE_MLSB, redcapValue: 5 },
      { value: ComplementaryTestConstants.MLSB_SA, redcapValue: 6 },
      { value: ComplementaryTestConstants.L_SA, redcapValue: 7 },
      { value: ComplementaryTestConstants.L_OR_LSA, redcapValue: 8 },
      { value: ComplementaryTestConstants.SA, redcapValue: 9 },
      {
        value: ComplementaryTestConstants.ENZYMATIC_INACTIVATION,
        redcapValue: 10
      },
      { value: ComplementaryTestConstants.EFFLUX, redcapValue: 11 },
      { value: ComplementaryTestConstants.INDUCIBLE_MLSB, redcapValue: 12 }
    ]
  },
  {
    id: 28,
    name: ComplementaryTestConstants.STAPH_BLACTAMS,
    answers: [
      { value: ComplementaryTestConstants.SUSCEPTIBLE, redcapValue: 1 },
      { value: ComplementaryTestConstants.PASE, redcapValue: 2 },
      { value: ComplementaryTestConstants.METHI_R, redcapValue: 3 },
      { value: ComplementaryTestConstants.UNKNOWN, redcapValue: 4 },
      { value: ComplementaryTestConstants.IN_PROGRESS, redcapValue: 5 },
      {
        value: ComplementaryTestConstants.PLP2A_OR_MECA_TO_PERFORM,
        redcapValue: 6
      },
      {
        value: ComplementaryTestConstants.PENICILLINASE_IN_PROCESS,
        redcapValue: 7
      }
    ]
  },
  {
    id: 31,
    name: ComplementaryTestConstants.STREPTO_MLS_PHENOTYPES,
    answers: [
      { value: ComplementaryTestConstants.NOT_AFFECTED, redcapValue: 1 },
      { value: ComplementaryTestConstants.WILD, redcapValue: 2 },
      { value: ComplementaryTestConstants.INDUCIBLE_MLSB, redcapValue: 3 },
      { value: ComplementaryTestConstants.EFFLUX, redcapValue: 4 },
      {
        value: ComplementaryTestConstants.INDUCIBLE_MLSB_OR_EFFLUX,
        redcapValue: 5
      },
      { value: ComplementaryTestConstants.MLSB, redcapValue: 6 },
      { value: ComplementaryTestConstants.CONSTITUTIVE_MLSB, redcapValue: 7 },
      {
        value: ComplementaryTestConstants.INDUCIBLE_OR_CONSTITUTIVE_MLSB,
        redcapValue: 8
      },
      { value: ComplementaryTestConstants.MLSB_OR_EFFLUX, redcapValue: 9 }
    ]
  },
  {
    id: 32,
    name: ComplementaryTestConstants.SYNERGY,
    answers: [
      { value: ComplementaryTestConstants.NO, redcapValue: 1 },
      { value: ComplementaryTestConstants.YES, redcapValue: 2 },
      {
        value: ComplementaryTestConstants.NO_AFTER_CONFIRMATION,
        redcapValue: 3
      }
    ]
  }
];
