import { SIRIValue } from "./SIRIValue";
/* Mapping between mechanism key in Redcap CSV and key in antibiogo export.
 * Read the following like this : 'AntibiogoSIR' : 'RedcapSIR'
 */
export const SIRIMappingCLSI: Map<string, SIRIValue> = new Map<
  string,
  SIRIValue
>([
  ["S", new SIRIValue("S", "s")],
  ["I", new SIRIValue("I", "i")],
  ["R", new SIRIValue("R", "r")],
  ["IP", new SIRIValue("N/A", "na")],
  ["PS", new SIRIValue("N/A", "na")],
  [".", new SIRIValue("N/A", "na")],
  ["SDD", new SIRIValue("SDD", "sdd")],
  ["TMIC", new SIRIValue("Request M.I.C.", "mic")],
  ["Diminished susceptibility", new SIRIValue("S", "s")],
  ["High level resistance", new SIRIValue("R", "r")],
  ["In progress", new SIRIValue("N/A", "na")],
  ["Intermediate", new SIRIValue("I", "i")],
  ["Intermediate level resistance", new SIRIValue("R", "r")],
  ["Low level resistance", new SIRIValue("R", "r")],
  ["MIC = @mic mg/l", new SIRIValue("N/A", "na")],
  ["No breakpoint", new SIRIValue("N/A", "na")],
  ["NOT CORRECT", new SIRIValue("N/A", "na")],
  ["request M.I.C.", new SIRIValue("Request M.I.C.", "mic")],
  ["Resistant", new SIRIValue("R", "r")],
  ["SUSCEPTIBLE", new SIRIValue("S", "s")],
  ['Susceptible "with reserve"', new SIRIValue("S", "s")],
  ["SUSCEPTIBLE DOSE DEPENDENT", new SIRIValue("SDD", "sdd")],
  ["Test in MIC", new SIRIValue("Request M.I.C.", "mic")]
]);
