import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import devicesCollection from "@/domain/devices";

import store from "@/store";
import firebase from "firebase/app";
import Timestamp = firebase.firestore.Timestamp;
import utils from "@/domain/utils";
import QRCode from "qrcode";
import { db, env } from "@/firebase";
import devices from "@/domain/devices";

@Component({})
export default class LinkedDevicesComponent extends Vue {
  //DATA
  lab: any = {};
  labId = this.$route.params.labId;
  devices: any[] = [];
  showQRCodeDialog = false;
  qrCodeImage = "";
  lastDeletedDevice: any | undefined;
  displayDeviceRemovedSnackbar = false;
  //LIFECYCLE
  mounted() {
    this.getLab(this.labId);
    this.listenToDevices(this.labId);
  }

  get labName() {
    if (this.lab !== undefined) {
      return this.lab.name;
    } else {
      return "";
    }
  }

  get labActiveVersion() {
    if (
      this.lab.config !== undefined &&
      this.lab.config.activeConfig !== undefined
    ) {
      return this.lab.config.activeConfig.version;
    } else {
      return undefined;
    }
  }

  formatUpdateDate(timestamp: Timestamp): string {
    return utils.formatDateRelative(timestamp.toDate());
  }

  //USER ACTION
  private async clickOnAddDevice() {
    let URI = "antibiogo-dev://new-device/" + this.labId;
    if (env === "production") {
      URI = "antibiogo-prod://new-device/" + this.labId;
    }

    try {
      this.qrCodeImage = await QRCode.toDataURL(URI);
      this.showQRCodeDialog = true;
    } catch (e) {
      console.error(e);
    }
  }

  private clickOnOpenInFirestore(deviceId: string) {
    const firestoreURL =
      `https://console.firebase.google.com/u/0/project/` +
      process.env.VUE_APP_FIREBASE_PROJECT +
      `/firestore/data/~2Flabs~2F${this.labId}~2Fdevices~2F${deviceId}`;
    window.open(firestoreURL);
  }

  private hideDialog() {
    this.showQRCodeDialog = false;
  }
  private async clickOnDeleteDevice(deviceToDelete: any) {
    try {
      await devicesCollection.deleteDevice(deviceToDelete.id, this.labId);
      this.lastDeletedDevice = deviceToDelete;
      this.displayDeviceRemovedSnackbar = true;
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }
  //FETCHING DATA
  private async getLab(labId: string) {
    try {
      const currentLab = await labsCollection.getLab(labId);
      this.lab = currentLab;
      store.dispatch("setCurrentLabName", currentLab.name);
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }

  private listenToDevices(labId: string) {
    try {
      db.collection("labs")
        .doc(labId)
        .collection("devices")
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            let device = change.doc.data();
            device.id = change.doc.id;
            if (change.type === "added") {
              if (
                device.deleted !== true &&
                device.config !== undefined &&
                device.config !== null
              ) {
                this.devices.push(device);
              }
            }
            if (change.type === "modified") {
              if (
                device.deleted !== true &&
                device.config !== undefined &&
                device.config !== null &&
                device.number !== undefined &&
                device.number !== null
              ) {
                if (this.devices.findIndex(d => d.id === device.id) !== -1) {
                  this.removeFromDevices(device);
                }
                this.devices.push(device);
              } else if (device.deleted === true) {
                //device deleted - no need to delete ghost device (with no config) has there already are excluded from the list
                this.removeFromDevices(device);
              }
            }
            if (change.type === "removed") {
              this.removeFromDevices(device);
            }
          });
        });
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }

  // TOOLS
  private removeFromDevices(deviceToDelete: any) {
    const indexToDelete = this.devices.findIndex(
      device => device.id === deviceToDelete.id
    );
    this.devices.splice(indexToDelete, 1);
  }
}
