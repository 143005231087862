import { ResistanceMechanism } from "@/domain/model/ResistanceMechanism";

export class RedcapComplexMechanism {
  constructor(
    public id: number,
    public name: String,
    public resistanceMechanisms: Array<ResistanceMechanism>
  ) {}
}

export const redcapComplexMechanisms: Array<RedcapComplexMechanism> = [
  {
    id: 2,
    name: "Methicillin-Resistant Staphylococcus aureus",
    resistanceMechanisms: [ResistanceMechanism.Staphylococcus_MRSA]
  },
  {
    id: 3,
    name: "Vancomycin Resistant Enterococcus",
    resistanceMechanisms: [ResistanceMechanism.Enterococcus_VRE]
  },
  {
    id: 4,
    name: "Inducible-Clindamycin Resistance",
    resistanceMechanisms: [
      ResistanceMechanism.Staphylococcus_IMLSb,
      ResistanceMechanism.Streptococcus_IMLSb
    ]
  },
  {
    id: 5,
    name: "Extended-Spectrum β-Lactamase",
    resistanceMechanisms: [ResistanceMechanism.Enterobacterales_ESBL]
  },
  {
    id: 6,
    name: "AmpC β-lactamases",
    resistanceMechanisms: [ResistanceMechanism.Enterobacterales_CASE_HL]
  },
  {
    id: 7,
    name: "Carbapenem Resistant Enterobacterales",
    resistanceMechanisms: [ResistanceMechanism.Enterobacterales_CBASE]
  },
  {
    id: 8,
    name: "Carbapenem-Resistant Acinetobacter Baumannii",
    resistanceMechanisms: [ResistanceMechanism.NotFermentingGramNeg_CRAB]
  },
  {
    id: 9,
    name: "Other",
    resistanceMechanisms: [
      ResistanceMechanism.Staphylococcus_VIRSA,
      ResistanceMechanism.Staphylococcus_CMLSb,
      ResistanceMechanism.Staphylococcus_Efflux,
      ResistanceMechanism.Staphylococcus_IMLSbOrEfflux,
      ResistanceMechanism.Enterobacterales_CASE,
      ResistanceMechanism.Enterobacterales_PASE,
      ResistanceMechanism.Enterobacterales_PASE_LL,
      ResistanceMechanism.Enterobacterales_PASE_HL,
      ResistanceMechanism.Enterobacterales_BL_HL,
      ResistanceMechanism.NotFermentingGramNeg_CASE_HL,
      ResistanceMechanism.NotFermentingGramNeg_CASE,
      ResistanceMechanism.NotFermentingGramNeg_OPRD2,
      ResistanceMechanism.NotFermentingGramNeg_NonEnzymaticRes,
      ResistanceMechanism.Enterococcus_GentamicinHighResistance,
      ResistanceMechanism.Streptococcus_CMLSB,
      ResistanceMechanism.Streptococcus_Efflux,
      ResistanceMechanism.Streptococcus_IMLSbOrEfflux
    ]
  },
  {
    id: 10,
    name: "Penicillinase",
    resistanceMechanisms: [
      ResistanceMechanism.Staphylococcus_PASE,
      ResistanceMechanism.Haemophilus_PASE
    ]
  },
  {
    id: 11,
    name: "Vancomycin-Resistant Staphylococcus Aureus",
    resistanceMechanisms: [ResistanceMechanism.Staphylococcus_VIRSA]
  },
  {
    id: 12,
    name: "Porine OprD2",
    resistanceMechanisms: [ResistanceMechanism.NotFermentingGramNeg_OPRD2]
  },
  {
    id: 13,
    name: "Enterobacterales and β-Lactams",
    resistanceMechanisms: [
      ResistanceMechanism.Enterobacterales_CASE,
      ResistanceMechanism.Enterobacterales_PASE,
      ResistanceMechanism.Enterobacterales_PASE_LL,
      ResistanceMechanism.Enterobacterales_PASE_HL,
      ResistanceMechanism.Enterobacterales_BL_HL,
      ResistanceMechanism.Enterobacterales_CASE_Chromosomal,
      ResistanceMechanism.Enterobacterales_CASE_Plasmidic
    ]
  },
  {
    id: 14,
    name: "Carbapenem-Resistant Pseudomonas Aeruginosa",
    resistanceMechanisms: [ResistanceMechanism.NotFermentingGramNeg_IRPA]
  },
  {
    id: 15,
    name: "Pneumococci and β-lactams",
    resistanceMechanisms: [ResistanceMechanism.Streptococcus_Diminished_Susceptible]
  }
];
