import Vue from "vue";
import Component from "vue-class-component";
import { ASTResult } from "@/model/ASTResult";
import store from "@/store";
import router from "@/router";
import astExport from "@/domain/astExport";
import redcapExport from "@/domain/redcap/redcapExport";
import { NotesMessageAlertsExportService } from "@/domain/NotesMessagesAlerts/NotesMessageAlertsExportService";
import { FileType } from "@/domain/FileType";

@Component({})
export default class AstExportResultsComponent extends Vue {
  results: ASTResult = this.$store.getters.astResults as ASTResult;
  displayLoaderOpenSheet: boolean = false;
  displayLoaderDownloadCSVRedcap: boolean = false;

  mounted() {
    if (this.results === null) {
      router.push("/astExport");
    }
  }

  get isAstLogsAvailable() {
    return this.results !== undefined && this.results.astLogs.length > 0;
  }

  private clickOnDownloadLogs() {
    if (this.results !== undefined && this.results.astLogs !== null) {
      const content = JSON.stringify(this.results.astLogs);
      const filename = astExport.getExportTitle(this.results.found);
      this.download(content, filename, FileType.JSON);
    } else {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }

  private clickOnOpenAstReport() {
    if (this.results === undefined) {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
      return;
    }
    this.displayLoaderOpenSheet = true;
    astExport
      .generateAstGoogleSheet(this.results, this.$gapi)
      .then(spreadsheetUrl => {
        window.open(spreadsheetUrl);
        this.displayLoaderOpenSheet = false;
      })
      .catch(error => {
        console.error(error);
        this.displayLoaderOpenSheet = false;
        store.dispatch(
          "setSnackbar",
          this.$t("snackbar.error.generic_message_contact_admin")
        );
      });
  }

  private clickOnDownloadRedcapAstExport() {
    if (this.results === undefined) {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
    this.displayLoaderDownloadCSVRedcap = true;
    redcapExport
      .generateExportData(this.results.astsReport)
      .then(redcapAstExport => {
        const content = redcapAstExport
          .flatMap(element => element.join(","))
          .join("\n");
        const fileName = astExport.getExportTitle(this.results.found);
        this.download(content, fileName, FileType.CSV);
        this.displayLoaderDownloadCSVRedcap = false;
      })
      .catch(error => {
        console.error(error);
        this.displayLoaderDownloadCSVRedcap = false;
        store.dispatch(
          "setSnackbar",
          this.$t("snackbar.error.generic_message_contact_admin")
        );
      });
  }

  private clickOnDownloadNotesAndMessages() {
    if (this.results === undefined) {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
    try {
      const notesMessageAlertsService = new NotesMessageAlertsExportService(
        this.results
      );
      const content = notesMessageAlertsService.getContent();
      const fileName = notesMessageAlertsService.getFileName();
      this.download(content, fileName, FileType.CSV);
    } catch (error) {
      console.error(error);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.generic_message_contact_admin")
      );
    }
  }

  private download(content: any, fileName: string, type: string) {
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}
