import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import store from "@/store";
import router from "@/router";
import utils from "@/domain/utils";
import axios from "axios";
import {
  allComplementaryTests,
  ComplementaryTest
} from "@/model/ComplementaryTest";
import { DefaultESAnswer } from "@/model/defaultESAnswer";

@Component({})
export default class ComplementaryTestsComponent extends Vue {
  // data
  labId = this.$route.params.labId;
  complementaryTests: ComplementaryTest[] = [];
  initialESAnswers: DefaultESAnswer[] = [];
  defaultComplementaryTests = allComplementaryTests;

  // lifecycle
  mounted() {
    this.getLab(this.labId);
  }

  // network
  private async getLab(labId: string) {
    try {
      const currentLab = await labsCollection.getLab(labId);
      store.dispatch("setCurrentLabName", currentLab.name); //used for breadcrumb

      this.initialESAnswers = currentLab.config.draftConfig.esDefaultAnswers;

      this.defaultComplementaryTests.forEach(complementaryTest => {
        let initialESAnswer = this.initialESAnswers?.find(
          answer => answer.key === complementaryTest.key
        );
        let test = { ...complementaryTest };

        test.status = initialESAnswer === undefined;

        this.complementaryTests.push(test);
      });
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_retrieve_lab_infos")
      );
    }
  }

  // user actions
  private clickOnBack() {
    router.back();
  }

  private async clickOnSave() {
    var newESAnswers: DefaultESAnswer[] = [];

    this.complementaryTests.forEach(test => {
      if (!test.status) {
        newESAnswers.push({ key: test.key, answer: test.answer });
      }
    });

    try {
      await labsCollection.updateESDefaultAnswers(this.labId, newESAnswers);
      this.$router.back();
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_save_complementary_tests")
      );
    }
  }

  private configHasNotChanged(): boolean {
    var statusHasChanged = false;

    this.complementaryTests.forEach(test => {
      let initialValue = this.initialESAnswers?.find(ea => ea.key === test.key);
      if (initialValue === undefined && test.status === false)
        statusHasChanged = true;
      else if (initialValue !== undefined && test.status === true)
        statusHasChanged = true;
    });

    return !statusHasChanged;
  }
}
