import Component from "vue-class-component";
import Vue from "vue";
import firebaseAuth from "@/domain/auth";
import router from "@/router";
import store from "@/store";
import { Watch } from "vue-property-decorator";
import DrawerComponent from "@/views/App/Drawer/Drawer.component.vue";

@Component({ name: "App", components: { DrawerComponent } })
export default class App extends Vue {
  private isLoggedIn = store.getters.user.loggedIn;
  //DATA
  breadcrumbList = [] as any[];
  displaySnackbar = false;
  snackbarText = "";

  get displayDrawerMenu() {
    return (
      this.$route.meta !== undefined &&
      this.$route.meta.displayDrawerMenu !== undefined &&
      this.$route.meta.displayDrawerMenu === true
    );
  }

  //LIFECYCLE
  mounted() {
    firebaseAuth.auth.onAuthStateChanged(user => {
      if (user !== null && user.email !== null) {
        store.dispatch("fetchUser", user);
        this.isLoggedIn = store.getters.user.loggedIn;
      }
    });
  }

  //Listening to route change to update breadcrumb dynamic routes
  @Watch("$route")
  onRouteChanged(value: any, oldValue: any) {
    if (
      value.meta.breadcrumbs !== undefined &&
      this.$route.meta !== undefined
    ) {
      let list = this.$route.meta.breadcrumbs;
      list.map((breadcrumbItem: any) => {
        if (breadcrumbItem.replaceLab !== undefined) {
          if (breadcrumbItem.replaceLab.pathTemplate !== undefined) {
            //Ugly hack to set dynamic href for the pages related to a specific lab (used in breadcrumb
            breadcrumbItem.href = breadcrumbItem.replaceLab.pathTemplate.replace(
              ":labId",
              this.$route.params.labId
            );
          }
          if (
            breadcrumbItem.replaceLab.name &&
            this.$store.getters.currentLabName !== undefined &&
            this.$store.getters.currentLabName !== ""
          ) {
            breadcrumbItem.text = this.$store.getters.currentLabName;
          }
        }
      });
      this.breadcrumbList = list;
    }
  }

  //Listenning to snackbar change
  @Watch("$store.state.snackbar")
  onSnackbarContentChanged(_value: any, _: any) {
    const snackbarContentText = this.$store.getters.snackbarContentText;
    if (snackbarContentText !== "") {
      this.snackbarText = snackbarContentText;
      this.displaySnackbar = true;
    }
  }

  //Listening to currentLab change to update breadcrumb dynamic name
  @Watch("$store.state.currentLabName")
  onCurrentLabChanged(value: any, oldValue: any) {
    if (value !== undefined && this.$route.meta !== undefined) {
      let list = this.$route.meta.breadcrumbs;
      list.map((breadcrumbItem: any) => {
        if (
          breadcrumbItem.replaceLab !== undefined &&
          breadcrumbItem.replaceLab.name
        ) {
          //Ugly hack to set dynamic name  pages related to a specific lab
          breadcrumbItem.text = value;
          if (breadcrumbItem.replaceLab.pathTemplate !== undefined) {
            //Ugly hack to set dynamic href for the pages related to a specific lab (used in breadcrumb
            breadcrumbItem.href = breadcrumbItem.replaceLab.pathTemplate.replace(
              ":labId",
              this.$route.params.labId
            );
          }
        }
      });
      this.breadcrumbList = list;
    }
  }

  //USER ACTIONS
  async clickOnLogOut() {
    try {
      await firebaseAuth.logout();
      store.dispatch("fetchUser", null);
      router.replace("/login");
      this.isLoggedIn = false;
      store.dispatch("resetRedirectionRoute");
    } catch (e) {
      console.error(e);
      store.dispatch("setSnackbar", this.$t("snackbar.error.cannot_logout"));
    }
  }

  clickOnBackOfficeTitleLogo() {
    router.push("/labs");
  }
}
