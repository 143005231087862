import Vue from "vue";
import Component from "vue-class-component";
import astExport from "@/domain/astExport";
import router from "@/router";
import store from "@/store";
import { env } from "@/firebase";

@Component({})
export default class AstExportComponent extends Vue {
  appTracks: any = [{ key: "dev", value: "Dev" }];
  buildTypes = [
    { key: "release", value: "Release" },
    { key: "debug", value: "Debug" }
  ];
  buildType = this.buildTypes[0];
  appTrack = this.appTracks[0];
  astIdsField = "";
  hashesField = "";
  displayExportInProgress = false;

  mounted() {
    if (env === "production") {
      this.appTracks = [
        { key: "research", value: "Research" },
        { key: "prod", value: "Prod" },
        { key: "routine", value: "Routine" },
        { key: "stable", value: "Stable" }
      ];
      this.appTrack = this.appTracks[0];
    }
  }

  // Computed properties
  get astIdsOrHashesRule() {
    const rules = [];
    if (this.astIdsField === "" && this.hashesField === "") {
      rules.push(this.$t("ast_export.ast_or_hash_required"));
    }
    return rules;
  }

  get isFormValid() {
    return (
      (this.astIdsField !== "" || this.hashesField !== "") &&
      this.buildType.key !== "" &&
      this.appTrack !== null &&
      this.appTracks.key !== ""
    );
  }

  // User actions
  async clickOnExport() {
    try {
      this.displayExportInProgress = true;
      const astIdsArray =
        this.astIdsField !== "" ? this.astIdsField.split(",") : [];
      const hashesArray =
        this.hashesField !== "" ? this.hashesField.split(",") : [];
      const astExportResults = await astExport.getAstExport(
        astIdsArray,
        hashesArray,
        this.appTrack.key,
        this.buildType.key,
        this.$gapi
      );

      store.dispatch("setAstResults", astExportResults);
      router.push("/astExport/results");
      this.displayExportInProgress = true;
    } catch (e) {
      console.error(e);
      this.displayExportInProgress = false;
      if (e.status === 403) {
        store.dispatch(
          "setSnackbar",
          this.$t("snackbar.error.generic_message_contact_admin")
        );
      } else {
        store.dispatch(
          "setSnackbar",
          this.$t("snackbar.error.no_complete_ast_found")
        );
      }
    }
  }
}
