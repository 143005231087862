import { db } from "@/firebase";

export default {
  getDevicesSnapshot(labId: string): Promise<any> {
    return new Promise<any>(resolve => {
      db.collection("labs")
        .doc(labId)
        .collection("devices")
        .onSnapshot(querySnapshot => {
          const devices: any = {};
          querySnapshot.forEach(doc => {
            let device = doc.data();
            if (device.config !== undefined && device.config !== null) {
              device.id = doc.id;
              devices[device.id] = device;
            }
          });
          resolve(devices);
        });
    });
  },
  async deleteDevice(deviceId: string, labId: string): Promise<boolean> {
    try {
      const querySnapshot = await db
        .collection("labs")
        .doc(labId)
        .collection("devices")
        .doc(deviceId)
        .set({ deleted: true }, { merge: true });
      return Promise.resolve(true);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }
};
