import Vue from "vue";
import Component from "vue-class-component";
import router from "@/router";
import { Watch } from "vue-property-decorator";

@Component({})
export default class DrawerComponent extends Vue {
  selectedItem = null;
  items = [
    {
      text: this.$t("labs.drawer_menu.labs"),
      icon: "mdi-flask-empty",
      path: "/labs"
    },
    {
      text: this.$t("labs.drawer_menu.logs_export"),
      icon: "mdi-code-not-equal-variant",
      path: "/astExport"
    }
  ];

  clickOnListItem(item: any, i: number) {
    if (item.path !== undefined) {
      router.push(item.path);
    }
  }

  mounted() {
    if (
      this.$route.meta !== undefined &&
      this.$route.meta.drawerMenuSelectedItemIndex !== undefined
    ) {
      this.selectedItem = this.$route.meta.drawerMenuSelectedItemIndex;
    }
  }

  //Listening to route change to update breadcrumb dynamic routes
  @Watch("$route")
  onRouteChanged(value: any, oldValue: any) {
    //Update drawer if necessary selection
    if (
      this.$route.meta !== undefined &&
      this.$route.meta.displayDrawerMenu !== undefined &&
      this.$route.meta.displayDrawerMenu &&
      this.$route.meta.drawerMenuSelectedItemIndex !== undefined
    ) {
      this.selectedItem = this.$route.meta.drawerMenuSelectedItemIndex;
    }
  }
}
