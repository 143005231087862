import moment from "moment";
import { SIRIMappingEUCAST } from "@/domain/siri/SIRIMappingEUCAST";
import { SIRIMappingCLSI } from "@/domain/siri/SIRIMappingCLSI";

export default {
  labConfigDeepEquals(object1: any, object2: any) {
    //all keys must be equals except version
    const keys1 = Object.keys(object1).filter(
      key => key !== "version" && key !== "lastUpdate"
    );
    const keys2 = Object.keys(object2).filter(
      key => key !== "version" && key !== "lastUpdate"
    );

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.labConfigDeepEquals(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  },

  isObject(object: any) {
    return object !== null && typeof object === "object";
  },

  filterVersionKey(key: string) {
    return key !== "version";
  },

  formatDateRelative(date: Date): string {
    return moment(date).fromNow();
  },

  formatDate(date: Date): string {
    return moment(date).format("L");
  },

  formatDateWithTime(date: Date): string {
    return moment(date).format("LLL"); // June 30, 2021 10:06 AM
  },

  // Remove timestamp from string
  // ex Input : FannyCSV (3).1624451611383.csv
  // ex Output : FannyCSV (3).csv
  formatOriginalFilename(name: string): string {
    const stringSplitDot = name.split(".");
    const timestampPosition = stringSplitDot.length - 2;
    const fileName = name
      .split("." + stringSplitDot[timestampPosition])
      .join("");
    if (fileName.length > 20) {
      return fileName.substr(0, 20) + "...";
    } else return fileName;
  },

  isKnowledgeBaseCLSI(knowledgeBase: String): boolean {
    return knowledgeBase.startsWith("clsi");
  },

  isKnowledgeBaseEUCAST(knowledgeBase: String): boolean {
    return knowledgeBase.startsWith("eucast");
  },

  getSIRISheetValues(siri: string, kb: string): string {
    if (this.isKnowledgeBaseEUCAST(kb)) {
      const siriValueEUCAST = SIRIMappingEUCAST.get(siri);
      return siriValueEUCAST ? siriValueEUCAST.sheetValue : "";
    } else if (this.isKnowledgeBaseCLSI(kb)) {
      const siriValueCLSI = SIRIMappingCLSI.get(siri);
      return siriValueCLSI ? siriValueCLSI.sheetValue : "";
    } else {
      return "";
    }
  }
};
