import {
  emptyNotesAndMessagesRow,
  NotesAndMessagesRow
} from "@/domain/NotesMessagesAlerts/NotesAndMessagesRow";
import astExport from "@/domain/astExport";
import { ASTResult } from "@/model/ASTResult";
import { notesMessagesAlertColumnsNameMap } from "@/domain/NotesMessagesAlerts/NotesMessagesAlertColumnsNameMap";

/**
 * Given asts extracts notes and messages in csv format
 */
export class NotesMessageAlertsExportService {
  private astResult: ASTResult;

  constructor(astResult: ASTResult) {
    this.astResult = astResult;
  }

  getContent(): string {
    const rows = this.astResult.astsReport.flatMap(ast => {
      const labSheet = ast.finalAntibiogram.notesStructured.labSheet.flatMap(
        (sheet: any) => sheet.message
      );
      const resultSheet = ast.finalAntibiogram.notesStructured.resultSheet.flatMap(
        (sheet: any) => sheet.message
      );
      const messages = ast.finalAntibiogram.messages.flatMap(
        (message: any) => message.content
      );
      const ipcAlerts = ast.ipcAlerts;
      const max_length = Math.max(
        ipcAlerts.length,
        messages.length,
        labSheet.length,
        resultSheet.length
      );
      const notesAndMessagesRows = [];
      for (let i = 0; i < max_length; i++) {
        notesAndMessagesRows.push(
          new NotesAndMessagesRow(
            this.convertToCSVRowString(i === 0 ? ast.astId : ""),
            this.convertToCSVRowString(labSheet[i]),
            this.convertToCSVRowString(resultSheet[i]),
            this.convertToCSVRowString(messages[i]),
            this.convertToCSVRowString(ipcAlerts[i])
          )
        );
      }
      notesAndMessagesRows.push(emptyNotesAndMessagesRow);
      return notesAndMessagesRows;
    });
    return this.convertToCSV(rows);
  }

  getFileName(): string {
    return `notes-messages-alerts-${astExport.getExportTitle(
      this.astResult.found
    )}`;
  }

  private convertToCSVRowString(string: string): string {
    return string ? `"${string.replaceAll('"', "'").trim()}"` : "";
  }

  private convertToCSV(arr: Array<any>): string {
    const headers = Object.keys(arr[0]).map(key =>
      notesMessagesAlertColumnsNameMap.get(key)
    );
    return [headers]
      .concat(arr)
      .map(it => Object.values(it).toString())
      .join("\n");
  }
}
