import { render, staticRenderFns } from "./ComplementaryTests.component.html?vue&type=template&id=6d06e06d&scoped=true&"
import script from "./ComplementaryTests.component.ts?vue&type=script&lang=ts&"
export * from "./ComplementaryTests.component.ts?vue&type=script&lang=ts&"
import style0 from "./ComplementaryTests.component.scss?vue&type=style&index=0&id=6d06e06d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d06e06d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VContainer,VList,VSpacer})
