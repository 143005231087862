import Vue from "vue";
import Component from "vue-class-component";
import firebaseAuth from "@/domain/auth";
import store from "@/store";
import router from "@/router";
import userCollection from "@/domain/users";

@Component({})
export default class NoAccessComponent extends Vue {
  mounted() {
    firebaseAuth.auth.onAuthStateChanged(user => {
      this.dispatchAndRedirectUser(user);
    });
  }

  async dispatchAndRedirectUser(user: firebase.User | null) {
    if (user === null || user.email === null) {
      return;
    }
    try {
      const isBackOfficeUser = await userCollection.isUserExist(user.email);
      if (isBackOfficeUser) {
        router.push("/labs");
      }
    } catch (e) {
      console.error(e);
    }
  }
}
