export enum LogAction {
  HelloKotlin = "HELLO_KOTLIN",

  FormSetSpecies = "form/setSpecies",
  FormSetUserName = "form/setUserName",
  FormSetSampleType = "form/setSampleType",

  CroppedPictureSet = "croppedPicture/set",
  NetworkTaskSucceeded = "networkTask/succeeded",

  AntibioticsAdd = "antibiotics/add",
  AntibioticsRemoveSelected = "antibiotics/removeSelected",

  AntibioticsStateSet = "ANTIBIOTICS_STATE::antibiotics/set",
  AntibioticsStateESStartFlow = "ANTIBIOTICS_STATE::ES/startFlow",
  AntibioticsStateESCompleteFlow = "ANTIBIOTICS_STATE::ES/completeFlow",
  AntibioticsStateUserEditedIZD = "ANTIBIOTICS_STATE::antibiotics/userEditedIZD",
  AntibioticsStateIPCAlerts = "ANTIBIOTICS_STATE::results/ipcAlerts",

  ESStartFlow = "ES/startFlow",
  ESBeforeRunExpertise = "ES/beforeRunExpertise",
  ESPromptFromES = "ES/promptFromES",
  ESUserAnsweredESQuestion = "ES/userAnsweredESQuestion",
  ESAnsweredESQuestion = "ES/answeredESQuestion",
  ESCompleteFlow = "ES/completeFlow",

  SetAstCompleted = "setAstCompleted",
  DeleteAstById = "deleteAstById"
}
