import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import router from "@/router";
import store from "@/store";
@Component({})
export default class LabsComponent extends Vue {
  //DATA
  labs = new Array<any>();
  showDialog = false;
  createNewLabName = "";

  //LIFECYCLE
  mounted() {
    this.getLabs();
  }

  //USER ACTIONS

  private async clickOnCreateLab() {
    if (this.createNewLabName === "") {
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_create_lab_with_no_name")
      );
      return;
    }
    try {
      const newLab = await labsCollection.createLab(this.createNewLabName);
      const currentLabList = this.labs;
      currentLabList.push(newLab);
      this.labs = currentLabList.sort((lab1, lab2) =>
        lab1.name.localeCompare(lab2.name)
      );
      this.hideDialog();
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.information.lab_added", { name: newLab.name })
      );
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_create_new_lab")
      );
    }
  }

  private hideDialog() {
    this.showDialog = false;
    this.createNewLabName = "";
  }

  private clickOnManageLab(lab: any) {
    store.dispatch("setCurrentLabName", lab.name);
    router.push({ path: `labhome/${lab.id}` });
  }

  private addLab() {
    this.showDialog = true;
  }

  //FETCHING DATA
  private async getLabs() {
    try {
      this.labs = await labsCollection.getLabs();
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_retrieve_labs")
      );
    }
  }
}
