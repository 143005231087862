import Vue from "vue";
import Component from "vue-class-component";
import labsCollection from "@/domain/labs";
import devicesCollection from "@/domain/devices";

import utils from "@/domain/utils";
import store from "@/store";
import router from "@/router";
import { allKnowledgeBase } from "@/model/KnowledgeBase";
import { allComplementaryTests } from "@/model/ComplementaryTest";

@Component({})
export default class LabHomeComponent extends Vue {
  lab: any = {};
  labId = this.$route.params.labId;
  error = false;
  linkedDevices = new Array<any>();
  displayUpdateDeviceLoader = false;

  //Lifecycle
  mounted() {
    this.getLab(this.labId);
    this.getLinkedDevicesInfos(this.labId);
  }

  beforeUnmount() {
    store.dispatch("resetCurrentLabName");
  }

  //Computed values
  get labName() {
    if (this.lab !== undefined) {
      return this.lab.name;
    } else {
      return "";
    }
  }

  get activeVersion() {
    if (
      this.lab.config !== undefined &&
      this.lab.config.activeConfig !== undefined
    ) {
      return this.lab.config.activeConfig.version;
    } else {
      return "";
    }
  }

  get activeConfigDate() {
    if (
      this.lab.config !== undefined &&
      this.lab.config.activeConfig !== undefined
    ) {
      const date = this.lab.config.activeConfig.lastUpdate.toDate();
      return utils.formatDateRelative(date);
    } else {
      return "";
    }
  }

  get importPictureEnabled() {
    if (
      this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined
    ) {
      return this.lab.config.draftConfig.importPictureEnabled;
    } else {
      return false;
    }
  }

  get knowledgeBase() {
    if (this.lab.config !== undefined && this.lab.config.draftConfig !== undefined) {
      const key = this.lab.config.draftConfig["knowledgeBase"];
      // TODO: Temporary fix, remove this below logic on multi KB story
      const knowledgeBase = allKnowledgeBase.find(kb => key.includes(kb.label.toLowerCase()));
      return knowledgeBase !== undefined ? knowledgeBase.label : "";
    } else {
      return "";
    }
  }

  get complementaryTestsDisabledCount() {
    var enabled = 0;
    if (
      this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined
    ) {
      if (this.lab.config.draftConfig.esDefaultAnswers !== undefined) {
        enabled = this.lab.config.draftConfig.esDefaultAnswers?.length;
      }
    }

    return enabled;
  }


  get complementaryTestsEnabledCount() {
    var disabled = 0;
    if (
      this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined
    ) {
      if (this.lab.config.draftConfig.esDefaultAnswers !== undefined) {
        disabled =
          allComplementaryTests.length -
          this.lab.config.draftConfig.esDefaultAnswers?.length;
      } else {
        disabled = allComplementaryTests.length;
      }
    }

    return disabled;
  }

  get hasChanged() {
    if (
      this.lab !== undefined &&
      this.lab.config &&
      this.lab.config.activeConfig !== undefined &&
      this.lab.config.draftConfig !== undefined
    ) {
      return !utils.labConfigDeepEquals(
        this.lab.config.activeConfig,
        this.lab.config.draftConfig
      );
    }
    return false;
  }

  get linkedDevicesCount() {
    if (this.linkedDevices !== undefined) {
      return this.linkedDevices.filter(device => !device.deleted).length;
    }
    return undefined;
  }

  get outOfDateLinkedDevicesCount() {
    if (
      this.linkedDevices !== undefined &&
      this.lab.config &&
      this.lab.config.activeConfig !== undefined
    ) {
      return this.linkedDevices.filter(
        device =>
          !device.deleted &&
          device.config["currentVersion"] < this.lab.config.activeConfig.version
      ).length;
    }
    return undefined;
  }

  get selectiveReportingEnabled() {
    return (
      this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined &&
      this.lab.config.draftConfig.selectiveReporting !== undefined &&
      this.lab.config.draftConfig.selectiveReporting !== null &&
      this.lab.config.draftConfig.selectiveReporting
        .selectiveAntibiogramRulesPath !== undefined &&
      this.lab.config.draftConfig.selectiveReporting
        .selectiveAntibiogramRulesPath !== ""
    );
  }

  get fileName() {
    return this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined &&
      this.lab.config.draftConfig.selectiveReporting !== undefined &&
      this.lab.config.draftConfig.selectiveReporting.originalFileName !==
        undefined
      ? utils.formatOriginalFilename(
          this.lab.config.draftConfig.selectiveReporting.originalFileName
        )
      : "";
  }

  get lastUploadDate() {
    return this.lab.config !== undefined &&
      this.lab.config.draftConfig !== undefined &&
      this.lab.config.draftConfig.selectiveReporting !== undefined &&
      this.lab.config.draftConfig.selectiveReporting.lastUploadDate !==
        undefined
      ? utils.formatDateWithTime(
          this.lab.config.draftConfig.selectiveReporting.lastUploadDate.toDate()
        )
      : "";
  }

  // data
  private async getLab(labId: string) {
    try {
      const currentLab = await labsCollection.getLab(this.labId);
      this.lab = currentLab;
      this.error = false;
      store.dispatch("setCurrentLabName", currentLab.name);
    } catch (e) {
      console.error(e);
      this.error = true;
      store.dispatch("setSnackbar", this.$t("error.cannot_retrieve_lab_infos"));
    }
  }

  private async getLinkedDevicesInfos(labId: string) {
    try {
      const devices = await devicesCollection.getDevicesSnapshot(labId);
      this.linkedDevices = Object.values(devices);
    } catch (e) {
      console.error(e);
      store.dispatch("setSnackbar", this.$t("generic_message_contact_admin"));
    }
  }

  // user actions
  private clickOnEditPictureImport() {
    router.push({ path: `/labhome/${this.lab.id}/pictureimport` });
  }

  private clickOnLinkedDevices() {
    router.push({ path: `/labhome/${this.lab.id}/linkeddevices` });
  }

  private clickOnKnowledgeBase() {
    router.push({ path: `/labhome/${this.lab.id}/knowledgebase` });
  }

  private clickOnComplementaryTests() {
    router.push({ path: `/labhome/${this.lab.id}/complementarytests` });
  }

  private clickOnSelectiveReporting() {
    router.push({ path: `/labhome/${this.lab.id}/selectivereporting` });
  }

  private async clickOnUpdateDevices() {
    try {
      this.displayUpdateDeviceLoader = true;
      this.lab.config = await labsCollection.saveConfig(this.labId);
      this.displayUpdateDeviceLoader = false;
      const devices = await devicesCollection.getDevicesSnapshot(this.labId);
      this.linkedDevices = Object.values(devices);

      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.information.configuration_sent_to_linked_devices")
      );
    } catch (e) {
      console.error(e);
      store.dispatch(
        "setSnackbar",
        this.$t("snackbar.error.cannot_send_configuration")
      );
      this.displayUpdateDeviceLoader = false;
    }
  }
}
