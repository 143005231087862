export class ASTResult {
  found: Array<Ast>;
  notFound: Array<Ast>;
  astsReport: Array<any>;
  astLogs: Array<any>;

  constructor(
    found: Array<Ast>,
    notFound: Array<Ast>,
    astsReport: Array<any>,
    astLogs: Array<any>
  ) {
    this.found = found;
    this.notFound = notFound;
    this.astsReport = astsReport;
    this.astLogs = astLogs;
  }
}

export class Ast {
  id: string | null;
  hash: string;

  constructor(id: string | null, hash: string) {
    this.id = id;
    this.hash = hash;
  }
}
