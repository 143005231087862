import { ResistanceMechanism } from "@/domain/model/ResistanceMechanism";
import utils from "@/domain/utils";
import { ComplementaryTestConstants } from "@/domain/redcap/constants/ComplementaryTestConstants";

/**
 * Takes an AST object and returns a set of resistance mechanisms found.
 * Mechanism of interest are defined per group or genus of bacteria
 */

export class ResistanceMechanismExtractor {
  private readonly resistanceMechanisms: Set<ResistanceMechanism>;
  private readonly groups: string;
  private readonly genus: string;
  private readonly knowledgeBase: string;
  private readonly messages: any;
  private readonly tests: any;
  private readonly antibiotics: any;

  private constructor(ast: any) {
    const antibiogram = ast.finalAntibiogram;
    this.messages = antibiogram.messages;
    this.tests = Object.entries(antibiogram.tests);
    this.antibiotics = antibiogram.antibiotics;
    this.groups = ast.groups;
    this.genus = ast.genus;
    this.knowledgeBase = ast.knowledgeBase;
    this.resistanceMechanisms = new Set<ResistanceMechanism>();
  }

  public static getResistanceMechanismsFor(ast: any) {
    return new ResistanceMechanismExtractor(ast).extractResistanceMechanisms();
  }

  private extractResistanceMechanisms(): Set<ResistanceMechanism> {
    if (this.genus.match("Staphylococcus")) {
      this.extractStaphylococcusMechanism();
    }
    if (this.groups.includes("Enterobacterales")) {
      this.extractEnterobacteralesMechanism();
    }
    if (this.groups.includes("Not fermenting gram neg bacilli")) {
      this.extractNotFermentingGramNegMechanism();
    }
    if (this.genus.match("Enterococcus")) {
      this.extractEnterococcusMechanism();
    }
    if (this.genus.match("Haemophilus")) {
      this.extractHaemophilusMechanism();
    }
    if (this.genus.match("Streptococcus")) {
      this.extractStreptococcusMechanism();
    }
    return this.resistanceMechanisms;
  }

  private extractStaphylococcusMechanism() {
    const vancomycinmic = "VANCOMYCIN (MIC)";
    const vancomycin_sus_message =
      "this strain should be susceptible to Vancomycin";
    const applicable_siri_codes = ["I", "R"];
    if (
      this.hasAntibioticWithGivenSiriCodes(
        vancomycinmic,
        applicable_siri_codes
      ) &&
      this.messages.some((message: any) =>
        message.content
          .toLowerCase()
          .includes(vancomycin_sus_message.toLowerCase())
      )
    ) {
      this.resistanceMechanisms.add(ResistanceMechanism.Staphylococcus_VIRSA);
    }

    this.tests.forEach((test: any[]) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();
      if (
        question ===
        ComplementaryTestConstants.STAPH_MLS_PHENOTYPES.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.CONSTITUTIVE_MLSB.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_CMLSb
            );
            break;
          case ComplementaryTestConstants.INDUCIBLE_MLSB.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_IMLSb
            );
            break;
          case ComplementaryTestConstants.EFFLUX.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_Efflux
            );
            break;
          case ComplementaryTestConstants.INDUCIBLE_MLSB_OR_EFFLUX.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_IMLSbOrEfflux
            );
            break;
        }
      }
      if (
        question === ComplementaryTestConstants.STAPH_BLACTAMS.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.METHI_R.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_MRSA
            );
            break;
          case ComplementaryTestConstants.PASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Staphylococcus_PASE
            );
            break;
        }
      }
    });
  }

  private extractEnterobacteralesMechanism() {
    this.tests.forEach((test: any[]) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();

      if (
        question === ComplementaryTestConstants.SYNERGY.toLowerCase() &&
        answer === ComplementaryTestConstants.YES.toLowerCase()
      ) {
        this.resistanceMechanisms.add(
          ResistanceMechanism.Enterobacterales_ESBL
        );
      }

      if (
        question ===
        ComplementaryTestConstants.ENTEROBACTERIACCEAE_BLACTAMS.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.LOW_LEVEL_PENICILLINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_PASE_LL
            );
            break;
          case ComplementaryTestConstants.HIGH_LEVEL_PASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_PASE_HL
            );

            break;
          case ComplementaryTestConstants.CEPHALOSPORINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE
            );
            break;
          case ComplementaryTestConstants.CEPHALOSPORINASE_PNASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE
            );
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_PASE
            );
            break;
          case ComplementaryTestConstants.HYPER_PRODUCED_BETA_LACTAMASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_BL_HL
            );
            break;
          case ComplementaryTestConstants.IRT_CASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE
            );
            break;
          case ComplementaryTestConstants.ESBL_HIGH_LEVEL_CEPHALOSPORINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE_HL
            );
            break;
          case ComplementaryTestConstants.ESBL_SUSPICION_HIGH_LEVEL_CASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE_HL
            );
            break;
          case ComplementaryTestConstants.ESBL_HIGH_LEVEL_B_LACTAMASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_BL_HL
            );
            break;
          case ComplementaryTestConstants.PROBABLE_HYPERPRODUCING_B_LACTAMASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_BL_HL
            );
            break;
        }
      }

      if (
        question ===
          ComplementaryTestConstants.CARBAPENEMASE_CONFIRMATION_TEST.toLowerCase() &&
        answer === ComplementaryTestConstants.POSITIVE.toLowerCase()
      ) {
        this.resistanceMechanisms.add(
          ResistanceMechanism.Enterobacterales_CBASE
        );
      }

      if (
        question ===
          ComplementaryTestConstants.PRODUCTION_OF_CARBAPENEMASE.toLowerCase() &&
        answer === ComplementaryTestConstants.YES.toLowerCase()
      ) {
        this.resistanceMechanisms.add(
          ResistanceMechanism.Enterobacterales_CBASE
        );
      }

      if (question === ComplementaryTestConstants.HCASE_TYPE.toLowerCase()) {
        switch (answer) {
          case ComplementaryTestConstants.CHROMOSOMIC.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE_Chromosomal
            );
            break;
          case ComplementaryTestConstants.PLASMIDIC.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Enterobacterales_CASE_Plasmidic
            );
            break;
        }
      }
    });
  }

  private extractNotFermentingGramNegMechanism() {
    this.tests.forEach((test: any[]) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();

      if (
        question ===
        ComplementaryTestConstants.P_AERUGIONOSA_B_LACTAMS.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.HYPERPROD_OF_CEPHALOSPORINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_CASE_HL
            );
            break;
          case ComplementaryTestConstants.HIGH_LEVEL_CEPHALOSPORINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_CASE_HL
            );
            break;
          case ComplementaryTestConstants.INDUCIBLE_CEPHALOSPORINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_CASE
            );
            break;
          case ComplementaryTestConstants.HCASE_PENICILLINASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_CASE_HL
            );
            break;
          case ComplementaryTestConstants.NOT_ENZYMATIC_RESISTANCE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_NonEnzymaticRes
            );
            break;
          case ComplementaryTestConstants.CARBAPENEMASE.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.NotFermentingGramNeg_IRPA
            );
            break;
        }
      }

      if (
        question ===
          ComplementaryTestConstants.PRODUCTION_OF_CARBAPENEMASE.toLowerCase() &&
        answer === ComplementaryTestConstants.YES.toLowerCase()
      ) {
        this.resistanceMechanisms.add(
          ResistanceMechanism.NotFermentingGramNeg_CRAB
        );
      }

      const imipenem_10µg = "IMIPENEM 10µg";
      const imipenem_mic = "IMIPENEM (MIC)";
      const ceftazidime_1µg = "CEFTAZIDIME 1µg";
      const ceftazidime_30µg = "CEFTAZIDIME 30µg";
      const ceftazidime_mic = "CEFTAZIDIME (MIC)";
      const loss_of_porin_message = "Beta-lactams : Loss of porin OprD2";
      if (
        (this.hasAntibioticWithGivenSiriCodes(imipenem_10µg, ["R"]) ||
          this.hasAntibioticWithGivenSiriCodes(imipenem_mic, ["R"])) &&
        (this.hasAntibioticWithGivenSiriCodes(ceftazidime_1µg, ["S"]) ||
          this.hasAntibioticWithGivenSiriCodes(ceftazidime_30µg, ["S"]) ||
          this.hasAntibioticWithGivenSiriCodes(ceftazidime_mic, ["S"])) &&
        this.messages.some((message: any) =>
          message.content
            .toLowerCase()
            .includes(loss_of_porin_message.toLowerCase())
        )
      ) {
        this.resistanceMechanisms.add(
          ResistanceMechanism.NotFermentingGramNeg_OPRD2
        );
      }
    });
  }

  private extractEnterococcusMechanism() {
    const gentamicin30µg = "GENTAMICIN 30µg";
    const gentamicin120µg = "GENTAMICIN 120µg";
    const highLevelResistance = "High level resistance";
    if (
      this.hasAntibioticWithGivenSiriStrings(gentamicin30µg, [
        highLevelResistance
      ]) ||
      this.hasAntibioticWithGivenSiriStrings(gentamicin120µg, [
        highLevelResistance
      ])
    ) {
      this.resistanceMechanisms.add(
        ResistanceMechanism.Enterococcus_GentamicinHighResistance
      );
    }

    let check_colony_message_flag = false;
    const vancomycin5µg = "VANCOMYCIN 5µg";
    const vancomycin30µg = "VANCOMYCIN 30µg";
    const applicable_siri_codes = ["R", "Request M.I.C."];
    if (
      this.hasAntibioticWithGivenSiriCodes(
        vancomycin5µg,
        applicable_siri_codes
      ) ||
      this.hasAntibioticWithGivenSiriCodes(
        vancomycin30µg,
        applicable_siri_codes
      )
    ) {
      check_colony_message_flag = true;
    }
    const vancomycin_mic = "VANCOMYCIN (MIC)";
    if (this.hasAntibioticWithGivenSiriCodes(vancomycin_mic, ["R"])) {
      check_colony_message_flag = true;
    }
    this.tests.forEach((test: any) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();
      if (
        question ===
          ComplementaryTestConstants.ENTEROCOCCI_COLONY_VANCO_TEICO.toLowerCase() &&
        answer === ComplementaryTestConstants.YES.toLowerCase()
      ) {
        check_colony_message_flag = true;
      }
      if (
        question ===
          ComplementaryTestConstants.ENTEROCOCCI_MEDIA_VANCO.toLowerCase() &&
        answer === ComplementaryTestConstants.YES.toLowerCase()
      ) {
        check_colony_message_flag = true;
      }
    });
    const coloniesMessage =
      "colonies are present in the inhibition zone of Vancomycin";
    if (
      check_colony_message_flag &&
      this.messages.some((message: any) =>
        message.content.includes(coloniesMessage)
      )
    ) {
      this.resistanceMechanisms.add(ResistanceMechanism.Enterococcus_VRE);
    }
  }

  private extractHaemophilusMechanism() {
    this.tests.forEach((test: any) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();

      if (
        question === ComplementaryTestConstants.PENICILLINASE.toLowerCase() &&
        answer === ComplementaryTestConstants.POSITIVE.toLowerCase()
      ) {
        this.resistanceMechanisms.add(ResistanceMechanism.Haemophilus_PASE);
      }
    });
  }

  private extractStreptococcusMechanism() {
    this.tests.forEach((test: any) => {
      const question = test[0].toLowerCase();
      const answer = test[1].toLowerCase();

      if (
        question ===
        ComplementaryTestConstants.STREPTO_MLS_PHENOTYPES.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.CONSTITUTIVE_MLSB.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Streptococcus_CMLSB
            );
            break;
          case ComplementaryTestConstants.INDUCIBLE_MLSB.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Streptococcus_IMLSb
            );
            break;
          case ComplementaryTestConstants.EFFLUX.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Streptococcus_Efflux
            );
            break;
          case ComplementaryTestConstants.INDUCIBLE_MLSB_OR_EFFLUX.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Streptococcus_IMLSbOrEfflux
            );
            break;
        }
      }

      if (
        question ===
        ComplementaryTestConstants.PNEUMOCOCCI_BLACTAMS.toLowerCase()
      ) {
        switch (answer) {
          case ComplementaryTestConstants.DIMISHED_SUSCEPT.toLowerCase():
            this.resistanceMechanisms.add(
              ResistanceMechanism.Streptococcus_Diminished_Susceptible
            );
            break;
        }
      }
    });
  }

  private hasAntibioticWithGivenSiriCodes(
    antibiotic_name: string,
    applicable_siri_codes: string[]
  ): boolean {
    if (antibiotic_name in this.antibiotics) {
      const antibiotic_siri_string = this.antibiotics[antibiotic_name].siri;
      const antibiotic_siri_code = utils.getSIRISheetValues(
        antibiotic_siri_string,
        this.knowledgeBase
      );
      return applicable_siri_codes.includes(antibiotic_siri_code);
    }
    return false;
  }

  private hasAntibioticWithGivenSiriStrings(
    antibiotic_name: string,
    applicable_siri_strings: string[]
  ): boolean {
    if (antibiotic_name in this.antibiotics) {
      const antibiotic_siri_string = this.antibiotics[antibiotic_name].siri;
      return applicable_siri_strings.includes(antibiotic_siri_string);
    }
    return false;
  }
}
