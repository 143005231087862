import Vue from "vue";
import Component from "vue-class-component";
import firebaseAuth from "@/domain/auth";
import store from "@/store";
import router from "@/router";
import userCollection from "@/domain/users";

@Component({})
export default class LoginComponent extends Vue {
  private user = {
    loggedIn: false,
    data: {}
  };

  mounted() {
    firebaseAuth.auth.onAuthStateChanged(user => {
      this.dispatchAndRedirectUser(user);
    });
  }
  private async dispatchAndRedirectUser(user: firebase.User | null) {
    if (user === null || user.email === null) {
      return;
    }

    this.dispatchFetchUserInfos(user);
    try {
      const isBackOfficeUser = await userCollection.isUserExist(user.email);
      if (isBackOfficeUser) {
        this.redirectUserToNextPage();
      } else {
        this.redirectUserToNoAccess();
      }
    } catch (e) {
      console.error(e);
      this.redirectUserToNoAccess();
    }
  }

  private redirectUserToNoAccess() {
    router.push("/noaccess");
    this.dispatchCannotFetchUserInfos();
    store.dispatch("resetRedirectionRoute");
  }

  private redirectUserToNextPage() {
    const redirectionRouteAfterSuccessLogin =
      store.getters.redirectionRouteAfterSuccessLogin;
    if (
      redirectionRouteAfterSuccessLogin !== undefined &&
      redirectionRouteAfterSuccessLogin !== ""
    ) {
      //User enter on Backoffice with a specific URL
      router.push(redirectionRouteAfterSuccessLogin);
      store.dispatch("resetRedirectionRoute");
    } else {
      //User enter on Backoffice by root URL
      if (this.$route.path !== "/labs") {
        router.push("/labs");
      }
    }
  }

  /* User actions */
  private async userClickOnLogIn() {
    try {
      await firebaseAuth.login();
    } catch (e) {
      console.error(e);
      store.dispatch("setSnackbar", this.$t("snackbar.error.cannot_login"));
    }
  }

  /* Store updates */
  private dispatchFetchUserInfos(user: firebase.User) {
    store.dispatch("fetchUser", user);
    this.user.data = user;
  }

  private dispatchCannotFetchUserInfos() {
    store.dispatch("fetchUser", null);
    this.user.data = {};
  }

  private dispatchRemoveUserInfos() {
    store.dispatch("fetchUser", null);
    this.user.data = {};
  }
}
