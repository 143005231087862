import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Lab from "../views/Labs/Labs.component.vue";
import LoginComponent from "../views/Login/Login.component.vue";
import store from "@/store";
import LabHomeComponent from "@/views/LabHome/LabHome.component.vue";
import PictureImportComponent from "@/views/LabHome/PictureImport/PictureImport.component.vue";
import NoAccessComponent from "@/views/NoAccess/NoAccess.component.vue";
import LinkedDevicesComponent from "@/views/LabHome/LinkedDevices/LinkedDevices.component.vue";
import KnowledgeBaseComponent from "@/views/LabHome/KnowledgeBase/KnowledgeBase.component.vue";
import ComplementaryTestsComponent from "@/views/LabHome/ComplementaryTests/ComplementaryTests.component.vue";
import SelectiveReportingComponent from "@/views/LabHome/SelectiveReporting/SelectiveReporting.component.vue";
import AstExportComponent from "@/views/AstExport/AstExport.component.vue";
import AstExportResultsComponent from "@/views/AstExport/AstExportResults/AstExportResults.component.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: NoAccessComponent
  },
  {
    path: "/labs",
    name: "labs",
    component: Lab,
    meta: {
      requiresAuth: true,
      displayDrawerMenu: true,
      drawerMenuSelectedItemIndex: 0,
      breadcrumbs: [{ text: "Labs", href: "/labs" }]
    }
  },
  {
    path: "/astExport",
    name: "astExport",
    component: AstExportComponent,
    meta: {
      requiresAuth: true,
      displayDrawerMenu: true,
      drawerMenuSelectedItemIndex: 1,
      breadcrumbs: [{ text: "AST export", href: "/astExport" }]
    }
  },
  {
    path: "/astExport/results",
    name: "astExportResults",
    component: AstExportResultsComponent,
    meta: {
      requiresAuth: true,
      displayDrawerMenu: true,
      drawerMenuSelectedItemIndex: 1,
      breadcrumbs: [
        { text: "AST export", href: "/astExport" },
        { text: "Results", href: "/astExport/results" }
      ]
    }
  },
  {
    path: "/labhome/:labId",
    name: "labHome",
    component: LabHomeComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, path: true }
        }
      ]
    }
  },
  {
    path: "/labhome/:labId/pictureimport",
    name: "pictureImport",
    component: PictureImportComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, pathTemplate: "/labhome/:labId" }
        },
        {
          text: "Picture import",
          href: "/labhome/:labId/pictureimport",
          replaceLab: {
            name: false,
            pathTemplate: "/labhome/:labId/pictureimport"
          }
        }
      ]
    }
  },
  {
    path: "/labhome/:labId/knowledgebase",
    name: "knowledgeBase",
    component: KnowledgeBaseComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, pathTemplate: "/labhome/:labId" }
        },
        {
          text: "Knowledge base",
          href: "/labhome/:labId/knowledgebase",
          replaceLab: {
            name: false,
            pathTemplate: "/labhome/:labId/knowledgebase"
          }
        }
      ]
    }
  },
  {
    path: "/labhome/:labId/complementarytests",
    name: "complementarytests",
    component: ComplementaryTestsComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, pathTemplate: "/labhome/:labId" }
        },
        {
          text: "Complementary Tests",
          href: "/labhome/:labId/complementarytests",
          replaceLab: {
            name: false,
            pathTemplate: "/labhome/:labId/complementarytests"
          }
        }
      ]
    }
  },
  {
    path: "/labhome/:labId/selectivereporting",
    name: "selectivereporting",
    component: SelectiveReportingComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, pathTemplate: "/labhome/:labId" }
        },
        {
          text: "Selective reporting",
          href: "/labhome/:labId/selectivereporting",
          replaceLab: {
            name: false,
            pathTemplate: "/labhome/:labId/selectivereporting"
          }
        }
      ]
    }
  },
  {
    path: "/labhome/:labId/linkeddevices",
    name: "linkeddevices",
    component: LinkedDevicesComponent,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { text: "Labs", href: "/labs" },
        {
          text: "",
          href: "/labhome/:labId",
          replaceLab: { name: true, pathTemplate: "/labhome/:labId" }
        },
        {
          text: "Linked devices",
          href: "/labhome/:labId/linkeddevices",
          replaceLab: {
            name: false,
            pathTemplate: "/labhome/:labId/linkeddevices"
          }
        }
      ]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (from.name !== "login" && to.name === "noaccess") {
    next("/login");
  }

  if (requiresAuth && !store.getters.user.loggedIn) {
    //we first save the direction ask by the user to be able to redirect to this direction after a successful login
    store.dispatch("setRedirectionRouteAfterSuccessLogin", to.path);
    next("/login");
  } else {
    next();
  }
});

export default router;
