export enum ResistanceMechanism {
  Staphylococcus_VIRSA,
  Staphylococcus_CMLSb,
  Staphylococcus_IMLSb,
  Staphylococcus_IMLSbOrEfflux,
  Staphylococcus_Efflux,
  Staphylococcus_MRSA,
  Staphylococcus_PASE,

  Enterobacterales_ESBL,
  Enterobacterales_CASE,
  Enterobacterales_CASE_Chromosomal,
  Enterobacterales_CASE_Plasmidic,
  Enterobacterales_CASE_HL,
  Enterobacterales_PASE,
  Enterobacterales_PASE_LL,
  Enterobacterales_PASE_HL,
  Enterobacterales_BL_HL,
  Enterobacterales_CBASE,

  NotFermentingGramNeg_CASE,
  NotFermentingGramNeg_CASE_HL,
  NotFermentingGramNeg_OPRD2,
  NotFermentingGramNeg_NonEnzymaticRes,
  NotFermentingGramNeg_CRAB,
  NotFermentingGramNeg_IRPA,

  Enterococcus_GentamicinHighResistance,
  Enterococcus_VRE,

  Haemophilus_PASE,

  Streptococcus_CMLSB,
  Streptococcus_IMLSb,
  Streptococcus_Efflux,
  Streptococcus_IMLSbOrEfflux,
  Streptococcus_Diminished_Susceptible
}
