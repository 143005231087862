export const ComplementaryTestConstants = {
  ACINETOBACTER_BETA_LACTAMS: "Acinetobacter & Beta-lactams",
  UNKNOWN: "Unknown",
  SUSCEPTIBLE: "Susceptible",
  PENICILLINASE: "Penicillinase",
  LOW_LEVEL_CEPHALOSPORINASE: "Low Level Cephalosporinase",
  HL_CEPHALOSPORINASE: "HL Cephalosporinase",
  PASE_LOW_LEVEL_CASE: "PASE + Low Level CASE",
  PASE_HIGH_LEVEL_CASE: "PASE + High Level CASE",
  APPEARANCE_OF_INHIBITION_ZONE: "Appearance of Inhibition Zone",
  SHARP: "Sharp",
  FUZZY: "Fuzzy",
  UNCERTAIN: "Uncertain",
  CARBAPENEMASE_CONFIRMATION_TEST: "Carbapenemase Confirmation Test",
  POSITIVE: "Positive",
  NEGATIVE: "Negative",
  NOT_PERFORMED: "Not Performed",
  IN_PROGRESS: "In Progress",
  ENTEROBACTERIACCEAE_BLACTAMS: "Enterobacteriacceae & Blactams",
  WILD: "Wild",
  LOW_LEVEL_PENICILLINASE: "Low Level Penicillinase",
  HIGH_LEVEL_PASE: "High Level PASE",
  CEPHALOSPORINASE: "Cephalosporinase",
  CEPHALOSPORINASE_PNASE: "Cephalosporinase + PNASE",
  HYPERPROD_OF_CEPHALOSPORINASE: "Hyperprod of Cephalosporinase",
  ESBL: "E.S.B.L.",
  UNKNOWN_PHENOTYPE: "Unknown Phenotype",
  IRT: "IRT",
  HYPER_PRODUCED_BETA_LACTAMASE: "Hyper Produced Beta Lactamase",
  IRT_CASE: "IRT+CASE",
  ESBL_SUSPICION: "ESBL Suspicion",
  CHROMOSOMAL_ESBL: "Chromosomal ESBL",
  ESBL_HIGH_LEVEL_CEPHALOSPORINASE: "ESBL+High Level Cephalosporinase",
  ESBL_SUSPICION_HIGH_LEVEL_CASE: "ESBL Suspicion + High Level CASE",
  ESBL_HIGH_LEVEL_B_LACTAMASE: "ESBL + High Level B-Lactamase",
  PROBABLE_HYPERPRODUCING_B_LACTAMASE: "Probable Hyperproducing B Lactamase",
  HIGH_LEVEL_PASE_HYPERP: "High Level PASE Hyperp.",
  ENTEROCOCCI_COLONY_VANCO_TEICO: "Enterococci - Colony Vanco / Teico",
  NO: "No",
  YES: "Yes",
  ENTEROCOCCI_AND_GLYCOPEPTIDES: "Enterococci and Glycopeptides",
  RESISTANT: "Resistant",
  RESISTANT_BY_MIC: "Resistant by MIC",
  HCASE_TYPE: "HCASE Type",
  CHROMOSOMIC: "Chromosomic",
  PLASMIDIC: "Plasmidic",
  INDUCTION: "Induction",
  PASE_TYPE: "PASE type",
  LOW_LEVEL_PASE: "Low Level PASE",
  CHROMOSOMIC_PASE: "Chromosomic PASE",
  PASE_TYPE_CASE: "PASE type CASE",
  NATURAL_PASE: "Natural PASE",
  IRT_PASE: "IRT+PASE",
  HIGH_LEVEL_PASE_OR_IRT: "High Level PASE or IRT",
  PNEUMOCOCCI_BLACTAMS: "Pneumococci & B-Lactams",
  DIMISHED_SUSCEPT: "Diminushed Suscept. ?",
  NOT_TESTED: "Not Tested",
  PRODUCTION_OF_CARBAPENEMASE: "Production of Carbapenemase",
  SUSPICION: "Suspicion",
  STAPH_MLS_PHENOTYPES: "Staph : MLS Phenotypes",
  NOT_AFFECTED: "Not Affected",
  NOT_DETERMINABLE: "Not Determinable",
  INDUCIBLE_MSLB_OR_EFFLUX: "Inducible MSLb or Efflux",
  CONSTITUTIVE_MLSB: "Constitutive MLSb",
  MLSB_SA: "MLSb +Sa",
  L_SA: "LSa",
  L_OR_LSA: "L or LSa",
  SA: "Sa",
  ENZYMATIC_INACTIVATION: "Enzymatic Inactivation",
  EFFLUX: "Efflux",
  INDUCIBLE_MLSB: "Inducible MLSb",
  STAPH_BLACTAMS: "Staph & B-Lactams",
  PASE: "PASE",
  METHI_R: "Methi-R",
  PLP2A_OR_MECA_TO_PERFORM: "PLP2A or MecA to perform",
  PENICILLINASE_IN_PROCESS: "Penicillinase in process",
  STREPTO_MLS_PHENOTYPES: "Strepto: MLS phenotypes",
  INDUCIBLE_MLSB_OR_EFFLUX: "Inducible MLSb or Efflux",
  MLSB: "MLSb",
  INDUCIBLE_OR_CONSTITUTIVE_MLSB: "Inducible or Constitutive MLSb",
  MLSB_OR_EFFLUX: "MLSb or Efflux",
  SYNERGY: "Synergy",
  NO_AFTER_CONFIRMATION: "No After Confirmation",
  P_AERUGIONOSA_B_LACTAMS: "P.AERUGINOSA & B-LACTAMS",
  HIGH_LEVEL_CEPHALOSPORINASE: "HIGH LEVEL CEPHALOSPORINASE",
  INDUCIBLE_CEPHALOSPORINASE: "INDUCIBLE CEPHALOSPORINASE",
  HCASE_PENICILLINASE: "HCASE + PENICILLINASE",
  NOT_ENZYMATIC_RESISTANCE: "NOT ENZYMATIC RESISTANCE",
  CARBAPENEMASE: "CARBAPENEMASE",
  ENTEROCOCCI_MEDIA_VANCO: "Enterococci : Media + Vanco"
};
